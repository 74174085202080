import { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation, Resources, TFuncKey } from "react-i18next";

interface Props {
  titleI18nKey: TFuncKey<keyof Resources>;
  descI18nKey: TFuncKey<keyof Resources>;
  nameSpace: keyof Resources;
  vars?: Record<string, string>;
}

export const PageMeta: FC<Props> = ({
  titleI18nKey,
  descI18nKey,
  nameSpace,
  vars,
}) => {
  const { t } = useTranslation(nameSpace);

  return (
    <Helmet>
      <title>Flex Alert: {t(titleI18nKey, vars)}</title>
      <meta name="title" content={`Flex Alert: ${t(titleI18nKey, vars)}`} />
      <meta name="description" content={t(descI18nKey, vars)} />
    </Helmet>
  );
};

export const pageWithMeta = (wrappedComponent: ReactNode, options: Props) => (
  <>
    <PageMeta {...options} />
    {wrappedComponent}
  </>
);
