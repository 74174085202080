import { DeepMap, FieldError } from "react-hook-form";
import { isValidUSPhoneNumber, REGEX_EMAIL } from "../..";
import { ProfileUsernameType } from "../../../modules/App/ProfileProvider/context";

export enum MessageType {
  ADD_ADDITIONAL_CONTACT,
  UPDATE_ADDITIONAL_CONTACT,
  NO_MESSAGE,
}

export const mapLabels = (
  usernameType: ProfileUsernameType | null,
  isAdditional = false,
) => {
  if (usernameType === ProfileUsernameType.EMAIL && isAdditional) {
    return "SMS alert";
  }

  if (usernameType === ProfileUsernameType.EMAIL && !isAdditional) {
    return "Email alert";
  }

  if (usernameType === ProfileUsernameType.MOBILE_NUMBER && isAdditional) {
    return "Email alert";
  }

  if (usernameType === ProfileUsernameType.MOBILE_NUMBER && !isAdditional) {
    return "SMS alert";
  }

  return "Alert";
};

export const validateInput =
  (usernameType: ProfileUsernameType | null) => (value?: string) => {
    if (!value) return false;

    switch (usernameType) {
      case ProfileUsernameType.MOBILE_NUMBER:
        return !!value.match(REGEX_EMAIL);
      case ProfileUsernameType.EMAIL:
        return isValidUSPhoneNumber(value);
      default:
        return false;
    }
  };

export const getErrorMsg = (
  errors: DeepMap<{ additionalContact: string }, FieldError>,
  usernameType?: ProfileUsernameType,
) => {
  switch (true) {
    case errors.additionalContact?.type === "required" &&
      usernameType === ProfileUsernameType.MOBILE_NUMBER:
      return "form.email.error.required";
    case errors.additionalContact?.type === "validate" &&
      usernameType === ProfileUsernameType.MOBILE_NUMBER:
    case errors.additionalContact?.type === "pattern" &&
      usernameType === ProfileUsernameType.MOBILE_NUMBER:
      return "form.email.error.pattern";
    case errors.additionalContact?.type === "required" &&
      usernameType === ProfileUsernameType.EMAIL:
      return "form.phone.error.required";
    case errors.additionalContact?.type === "validate" &&
      usernameType === ProfileUsernameType.EMAIL:
    case errors.additionalContact?.type === "pattern" &&
      usernameType === ProfileUsernameType.EMAIL:
      return "form.phone.error.pattern";
    default:
      return "";
  }
};

export const MODIFY_INTERVAL_IN_MS = +(
  process.env.REACT_APP_ADDITIONAL_CONTACT_UPDATE_TIME || 604800000
);
export const MODIFY_INTERVAL_IN_DAYS =
  MODIFY_INTERVAL_IN_MS / (1000 * 60 * 60 * 24);
