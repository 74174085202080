class PublicAddressClass {
  KEY = "publicAddress";
  DID_PREFIX = "did:ewc:";

  has() {
    return this.get() !== null;
  }

  /**
   * This function will be used for API-related cases where the address format is needed
   * @returns Digital Identity in Address Format
   */
  get() {
    return localStorage.getItem(this.KEY);
  }

  /**
   * This function must be used to supply to generating identicons and display of Digital Identity
   * @returns Digital Identity in DID Format
   */
  getDID() {
    return `${this.DID_PREFIX}${localStorage.getItem(this.KEY)}`;
  }

  set(value: string) {
    if (!value) {
      return;
    }
    return localStorage.setItem(this.KEY, value);
  }

  clear() {
    localStorage.removeItem(this.KEY);
  }
}

export const PublicAddressService = new PublicAddressClass();
// Usage: PublicAddressService.set("b6aida7dags")
