import { FC, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  FlexApiService,
  getUsernameType,
  isBrowserPaired,
  isExistsZIPCode,
  LoggingService,
  wipeSubscriberData,
  ZipCodeDoesNotExist,
} from "../../../common";
import { ROUTES_PATHS } from "../router";
import {
  Profile,
  ProfileContext,
  ProfileError,
  ProfileWithUsernameType,
} from "./context";
import {
  encryptProfile,
  fetchProfile,
  createNewProfile,
  saveEncryptedProfileForLocation,
  decryptProfile,
} from "./helper";

interface Props {
  children: ReactNode;
}

export const ProfileProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [profile, setStateProfile] =
    useState<ProfileWithUsernameType | null>(null);
  const [canUpdateProfile, setCanUpdateProfile] = useState<boolean>(true);
  const [error, setError] = useState<ProfileError | null>(null);
  const [isFetchingProfile, setIsFetchingProfile] = useState(true);

  const fetchAndSetProfile = async () => {
    try {
      const fetchedProfile = await fetchProfile();

      if (!fetchedProfile) {
        LoggingService.error("Profile could not be found");
        FlexApiService.disableAuthorizationHeader();
        setError(ProfileError.NOT_FOUND);
        return;
      }

      const encryptedProfile = fetchedProfile?.profile;

      if (!encryptedProfile) {
        LoggingService.error("Profile data corrupted");
        FlexApiService.disableAuthorizationHeader();
        setError(ProfileError.DATA_CORRUPTED);
        return;
      }

      const newProfile = {
        ...encryptedProfile,
        mainContactType: getUsernameType(encryptedProfile.mainContact),
        contactModifyInterval: CONTACT_MODIFY_INTERVAL,
      };

      if (fetchedProfile?.contactModifiedDate) {
        const nowDate = Date.now();
        const contactModifiedDate = new Date(
          fetchedProfile?.contactModifiedDate,
        );

        setCanUpdateProfile(
          nowDate >= contactModifiedDate.getTime() + CONTACT_MODIFY_INTERVAL,
        );
      }

      setError(null);
      setStateProfile(newProfile);
      return newProfile;
    } catch (e) {
      setError(ProfileError.UNKNOWN);
      LoggingService.error(`Error while fetching profile: `, e);
      navigate(ROUTES_PATHS.ERROR.INTERNAL, {
        state: {
          forceReloadApp: true,
        },
      });
    } finally {
      setIsFetchingProfile(false);
    }
  };

  const createProfile = async (newProfile: Profile, id: string) => {
    // Profile is already existed
    if (profile?.uuid) {
      return profile;
    }

    const encryptedProfile = encryptProfile(newProfile);

    if (!encryptedProfile) {
      return false;
    }

    const response = await createNewProfile(encryptedProfile, id);

    if (!(response.status === 200 || response.status === 409)) {
      wipeSubscriberData();
      LoggingService.error("Create profile status code: ", response.status);
      navigate(ROUTES_PATHS.ERROR.MAIN);
      return false;
    }

    if (response.status === 409) {
      const profile = decryptProfile(response.data.encryptedProfile);

      if (!profile) {
        LoggingService.error("Profile could not be decrypted");
        navigate(ROUTES_PATHS.ERROR.MAIN);
        return false;
      }

      const profileWithUsernameType = {
        ...profile,
        mainContactType: getUsernameType(profile.mainContact),
        contactModifyInterval: CONTACT_MODIFY_INTERVAL,
      };
      setStateProfile(profileWithUsernameType);

      setError(null);

      return profileWithUsernameType;
    }

    const newProfileWithUsernameType = {
      ...newProfile,
      mainContactType: getUsernameType(newProfile.mainContact),
      contactModifyInterval: CONTACT_MODIFY_INTERVAL,
    };
    setStateProfile(newProfileWithUsernameType);
    setError(null);

    return newProfileWithUsernameType;
  };

  const setLocation = async (newLocation: string) => {
    if (!(await isExistsZIPCode(newLocation))) {
      throw new ZipCodeDoesNotExist();
    }

    if (!profile) {
      throw Error("Missing profile");
    }

    // Update Encrypted Profile
    const newProfile = { ...profile, location: newLocation };
    const newEncryptedProfile = encryptProfile(newProfile);
    if (!newEncryptedProfile) {
      throw Error("Profile could not be encrypted");
    }
    await saveEncryptedProfileForLocation(newEncryptedProfile);

    // Update Profile State
    setStateProfile(newProfile);
  };

  const getNewEncryptedProfileWithAdditionalContact = (
    additionalContact: string,
    profileRef?: ProfileWithUsernameType,
  ) => {
    const currentProfile = profile || profileRef;
    if (!currentProfile) {
      return null;
    }

    const newProfile = { ...currentProfile, additionalContact };
    const encryptedProfile = encryptProfile(newProfile);
    return encryptedProfile;
  };

  const updateAdditionalContact = (additionalContact: string) => {
    setStateProfile((prevState) => {
      if (!prevState) return null;
      return { ...prevState, additionalContact };
    });
    setCanUpdateProfile(false);
  };

  const refetchProfile = async () => {
    return fetchAndSetProfile();
  };

  useEffect(() => {
    if (!isBrowserPaired()) {
      setIsFetchingProfile(false);
      return;
    }

    fetchAndSetProfile();
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        isFetchingProfile,
        profile,
        error,
        canUpdateProfile,
        createProfile,
        setLocation,
        updateAdditionalContact,
        getNewEncryptedProfileWithAdditionalContact,
        refetchProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const CONTACT_MODIFY_INTERVAL = +(
  process.env.REACT_APP_ADDITIONAL_CONTACT_UPDATE_TIME || 604800000
);
