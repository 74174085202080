import styled, { css } from "styled-components";
import MyLocationRawIcon from "@material-ui/icons/MyLocation";
import { COLORS } from "../../../../common/colors";
import { Input, SubHeading, H6 } from "../..";

export const ZipFormWrapper = styled.section`
  ${SubHeading}:first-of-type {
    margin-bottom: 25px;
  }

  ${SubHeading}:last-of-type, ${H6} {
    margin-bottom: 10px;
  }

  margin-top: 60px;

  @media (max-width: 800px) {
    margin-top: 30px;
  }
`;

export const ZipForm = styled.form`
  display: flex;
  max-width: 500px;
  height: 75px;

  & > button {
    height: 60px;
    margin-left: 16px;
  }

  @media (max-width: 380px) {
    flex-direction: column;

    ${Input} {
      height: fit-content;
      margin-right: 0;
    }

    & > button {
      margin-top: 8px;
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 380px) {
    height: unset;
  }
`;

interface MyLocationIconProps {
  $featured?: boolean;
}
export const MyLocationIcon = styled(MyLocationRawIcon)<MyLocationIconProps>`
  ${(props) =>
    props.$featured &&
    css`
      color: ${COLORS.PRIMARY.MAIN};
    `}
`;
