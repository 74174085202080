import { FC, ReactNode } from "react";
import { ErrorBoundary } from "@sentry/react";
import { Navigate } from "react-router-dom";
import { ROUTES_PATHS } from "..";

interface Props {
  children: ReactNode;
}

export const SentryProvider: FC<Props> = ({ children }) => {
  if (process.env.REACT_APP_USE_SENTRY === "true") {
    return (
      <ErrorBoundary fallback={<Navigate to={ROUTES_PATHS.ERROR.INTERNAL} />}>
        {children}
      </ErrorBoundary>
    );
  }

  return <>{children}</>;
};
