import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import resources
import { enNamespaces } from "./locales";

export const resources = {
  en: {
    ...enNamespaces,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  debug: process.env.NODE_ENV === "development",

  interpolation: {
    escapeValue: false,
  },

  ns: ["registration_page", "layout"],
  resources,
});

export default i18n;
