import { AxiosResponse } from "axios";
import { FlexApiService } from ".";
import { isValidZIPCode } from "../formRules";
import { Position } from "../interfaces";
import { LoggingService } from "./logging";

type MapboxAPIResponse = {
  type: string;
  query: string[];
  features: {
    id: string;
    text: string;
    center: Position;
    place_name: string;
  }[];
};

type ZipCodeAPIResponse = {
  zipCode: string;
  city: string;
  latitude: number;
  longitude: number;
}[];

type ZipCodeAPIPayload = never;

class GeocodingClass {
  async getZIPCodeByPosition(position: Position) {
    const url = new URL(
      `${process.env.REACT_APP_MAPBOX_API_URL}/${position[1] /* longitude */},${
        position[0] /* latitude */
      }.json`,
    );

    const params = new URLSearchParams();
    params.set("access_token", process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "");
    url.search = params.toString();

    let response: Response;

    try {
      response = await fetch(url.toString());
    } catch (error) {
      LoggingService.error("Mapbox API Error: ", error);
      return null;
    }

    if (!response.ok) {
      return null;
    }

    const data = (await response.json()) as MapboxAPIResponse;

    const postcodeIdx = data.features.findIndex((feature) =>
      feature.id.startsWith("postcode."),
    );

    if (postcodeIdx < 0) {
      return null;
    }

    const foundedZIPCode = data.features[postcodeIdx].text;

    if (!isValidZIPCode(foundedZIPCode)) {
      throw Error(GeocodingServiceErrors.INVALID_ZIP);
    }

    return foundedZIPCode;
  }

  async getPositionByZIPCode(zipCode: string) {
    let response: AxiosResponse<ZipCodeAPIResponse>;

    try {
      response = await FlexApiService.get<
        ZipCodeAPIResponse,
        ZipCodeAPIPayload
      >(`geolocation?q=${zipCode}`);
    } catch {
      return null;
    }

    if (response.status !== 200) {
      return null;
    }

    const data = response.data;

    if (data.length === 0) {
      return null;
    }

    const foundedElement = data.find((d) => d.zipCode === zipCode);

    if (!foundedElement) return null;

    return [foundedElement.latitude, foundedElement.longitude] as Position;
  }

  async getLocation() {
    // Geolocation API is not supported
    if (!navigator.geolocation) {
      return null;
    }

    return new Promise<Position>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        resolve([coords.latitude, coords.longitude]);
      }, reject);
    });
  }
}

export enum GeocodingServiceErrors {
  INVALID_ZIP = "INVALID_ZIP",
}

export const GeocodingService = new GeocodingClass();
// Usage: await GeocodingService.getPositionByZIPCode("90062");
