import { FC } from "react";
import { useTranslation } from "react-i18next";
import { H6 } from "../..";
import {
  Wrapper,
  Map,
  MapContainer,
  LegendContainer,
  LegendTitle,
} from "./components";
import { ReactComponent as MapLegend } from "./map_legend.svg";

interface Props {
  data?: {
    county: string;
    latitude: number;
    longitude: number;
    ratio: number;
  }[];
}

export const CloroplethMap: FC<Props> = ({ data }) => {
  const { t } = useTranslation("event_page");

  return (
    <Wrapper>
      <H6>{t("cloropleth.map.heading")}</H6>
      <MapContainer>
        <Map $data={data || []} />
        <LegendContainer>
          <LegendTitle>{t("cloropleth.map.legend.high")}</LegendTitle>
          <MapLegend />
          <LegendTitle>{t("cloropleth.map.legend.low")}</LegendTitle>
        </LegendContainer>
      </MapContainer>
    </Wrapper>
  );
};
