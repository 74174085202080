import styled from "styled-components";
import logoGreenSrc from "../../../assets/images/logo_green.png";
import logoCaisoSrc from "../../../assets/images/caiso_logo.png";
import logoCaisoSingleSrc from "../../../assets/images/caiso_single_logo.png";

export const GreenLogoHeader = styled.img.attrs(() => ({
  src: logoGreenSrc,
}))`
  height: 75px;

  @media only screen and (max-width: 500px) {
    height: 48px;
  }
`;

export const CaisoLogoHeader = styled.img.attrs(() => ({
  src: logoCaisoSrc,
}))`
  height: 40px;
  margin: auto 0;
  margin-left: 40px;

  @media only screen and (max-width: 500px) {
    height: 24px;
    margin-left: 15px;
  }
`;

export const GreenLogoFooter = styled.img.attrs(() => ({
  src: logoGreenSrc,
}))`
  height: 40px;

  @media only screen and (max-width: 500px) {
    height: 38px;
  }
`;

export const CaisoLogoFooter = styled.img.attrs(() => ({
  src: logoCaisoSrc,
}))`
  height: 20px;
  margin: auto 0;
  margin-left: 30px;
  display: inline-block;

  @media only screen and (max-width: 500px) {
    display: none;
    height: 20px;
    margin-left: 20px;
  }
`;

export const CaisoLogoSingleFooter = styled.img.attrs(() => ({
  src: logoCaisoSingleSrc,
}))`
  height: 40px;
  margin-left: 30px;
  display: none;

  @media only screen and (max-width: 500px) {
    display: inline-block;
    height: 38px;
    margin-left: 15px;
  }
`;
