import { useState } from "react";
import { UseFormSetError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../../modules";
import { ToastType } from "../../../../modules/App/ToastProvider";
import { isExistsZIPCode } from "../../../formRules";
import {
  FlexApiService,
  LoggingService,
  ResponseService,
  isBrowserPaired,
  useCaptcha,
} from "../../..";
import { ZipFormValues } from "./helpers";
import { signResponse } from "../../../../modules/Event/SuccessfulParticipation/helpers";

export const useUpdateResponseLocation = (
  setError?: UseFormSetError<ZipFormValues>,
) => {
  const { executeRecaptcha } = useCaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { showToast } = useToast();
  const { t } = useTranslation("event_page");

  const onSubmit = async ({ zipCode }: ZipFormValues) => {
    try {
      setIsLoading(true);

      const captcha = await executeRecaptcha();
      const eventResponse = ResponseService.get();

      if (!eventResponse) {
        return;
      }

      if (!isExistsZIPCode(zipCode)) {
        setError?.("zipCode", {
          type: "validate",
          shouldFocus: false,
        });
        return;
      }

      const payload: ResponseLocationAPIPayload = {
        responseId: `${eventResponse.responseId}`,
        zipCode,
        captcha,
      };

      if (isBrowserPaired()) {
        payload.signature = signResponse(
          eventResponse.alertId,
          eventResponse.source,
          zipCode,
        );
      }

      const response = await FlexApiService.post<
        ResponseLocationAPIResponse,
        ResponseLocationAPIPayload
      >("response/location", payload);

      console.log(response);

      switch (response.status) {
        // 201 Success
        // 404 Response not found
        case 201:
        case 404:
          showToast(
            t(
              isBrowserPaired()
                ? "zipcode.success"
                : "zipcode.success.anonymous",
            ),
          );
          ResponseService.setZipCode(zipCode);
          break;
        // Alert date exceeded
        case 403:
          setIsDisabled(true);
          showToast(t("zipcode.date.exceeded"), ToastType.ERROR);
          break;
          break;
        // Response signature not valid | Public address doesn't match
        case 401:
          setIsDisabled(true);
          showToast(t("zipcode.error.paired"), ToastType.ERROR);
          break;
        default:
          break;
      }
    } catch (e) {
      showToast(t("zipcode.error"), ToastType.ERROR);
      LoggingService.error("Error while updating response location: ", e);
    } finally {
      setIsLoading(false);
    }
  };

  return [onSubmit, { isLoading, isDisabled }] as const;
};

type ResponseLocationAPIPayload = {
  responseId: string;
  zipCode: string;
  signature?: string;
  captcha: string;
};

type ResponseLocationAPIResponse = {
  alertId: string;
  validFrom: string;
  validTo: string;
};
