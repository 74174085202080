import L from "leaflet";
import markerIconUrl from "./markerIcon.svg";

export const getMapboxTileUrl = () => {
  return `https://api.mapbox.com/styles/v1/${process.env.REACT_APP_MAPBOX_USERNAME}/${process.env.REACT_APP_MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
};

export const markerIcon = L.icon({
  iconUrl: markerIconUrl,
});
