/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureMessage } from "@sentry/react";

class LoggingClass {
  private ENABLED_LOG = process.env.NODE_ENV === "development";

  setEnabled(enabled: boolean) {
    this.ENABLED_LOG = enabled;
  }

  isEnabled() {
    return this.ENABLED_LOG;
  }

  info(...args: any[]) {
    if (!this.ENABLED_LOG) return;
    console.log(...args);
  }

  warning(...args: any[]) {
    if (!this.ENABLED_LOG) return;
    console.warn(...args);
  }

  error(...args: any[]) {
    try {
      if (process.env.REACT_APP_USE_SENTRY === "true") {
        captureMessage(args.map((a) => a.toString()).join("; "));
      }
    } catch {
      if (process.env.REACT_APP_USE_SENTRY === "true") {
        captureMessage("Not parsable error");
      }
    }
    if (!this.ENABLED_LOG) return;
    console.error(...args);
  }
}

export const LoggingService = new LoggingClass();
// Usage: LoggingService.info("log", "next log");
