import { lazy } from "react";

export const LazyRegistration = lazy(async () => ({
  default: (
    await import("./Registration" /* webpackChunkName: "Registration" */)
  ).Registration,
}));

export * from "./Sent";
export * from "./SignUp";
export * from "./SuccessfullySubscribed";
export * from "./TokenInvalid";
export * from "./Confirm";
export * from "./ResubscribeSent";
