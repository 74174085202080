import { withStyles } from "@material-ui/core";
import RawCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { COLORS } from "../../../colors";

export const Checkbox = withStyles({
  root: {
    color: COLORS.SECONDARY.LIGHT,
    "&$checked": {
      color: COLORS.PRIMARY.MAIN,
    },
  },
  checked: {},
})((props: CheckboxProps) => <RawCheckbox color="default" {...props} />);
