import { FormEvent } from "react";
import { InputBaseComponentProps } from "@material-ui/core";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import { ZipCodeAPIResponse } from "./zipcodeinput.api";

export interface RenderInputParams extends AutocompleteRenderInputParams {
  inputProps: InputBaseComponentProps;
}

export const isOptionSelected = (
  option: ZipCodeAPIResponse[number],
  value: ZipCodeAPIResponse[number],
) => {
  return option.zipCode === value.zipCode;
};

export const getOptionLabel = (option: ZipCodeAPIResponse[number] | string) => {
  if (typeof option === "string") {
    return option;
  }
  return option.zipCode;
};

export const formatZIPInput = (
  event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const value = event.currentTarget.value;
  // Only accept numbers and change length to 5
  const newValue = value.replace(/\D/g, "").slice(0, 5);
  event.currentTarget.value = newValue;
  return event;
};
