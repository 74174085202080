import MaskedInput from "react-text-mask";

interface InputPhoneNumberMaskProps {
  inputRef: (ref: HTMLElement | null) => void;
}

const disableMask = !["production", "staging"].includes(
  `${process.env.REACT_APP_STAGE}`,
);

export const InputPhoneNumberMask = disableMask
  ? undefined
  : (props: InputPhoneNumberMaskProps) => {
      const { inputRef, ...other } = props;

      return (
        <MaskedInput
          {...other}
          ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
          }}
          guide={false}
          mask={[
            "+",
            "1",
            " ",
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          placeholderChar={"\u2000"}
        />
      );
    };
