import { State } from "history";
import { useNavigate } from "react-router";

export const useRedirect = () => {
  const navigate = useNavigate();

  const redirect = (route: string, state?: State) => {
    navigate(route, {
      state: { ...state, flag: true },
    });
  };

  return redirect;
};
