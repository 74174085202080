import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATHS, useProfile, useToast } from "../../../modules";
import { ToastType } from "../../../modules/App/ToastProvider";
import { isValidUSPhoneNumber } from "../../formRules";
import { FlexApiService, LoggingService, useCaptcha } from "../..";

export const useResubscribe = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { executeRecaptcha } = useCaptcha();
  const { showToast } = useToast();
  const { t } = useTranslation("settings");

  const resubscribe = async (data: Omit<SubscribedAlertsAPIPayload, "id">) => {
    if (!profile) return;

    try {
      setIsLoading(true);

      const captchaToken = await executeRecaptcha();
      data.captcha = captchaToken;
      const response = await FlexApiService.post<
        never,
        SubscribedAlertsAPIPayload
      >("subscriber/resubscribe", { id: profile.uuid, ...data });

      if (response.status !== 201) {
        LoggingService.error(
          "Error while resubscribe: status code doesn't match",
        );

        showToast(t("toast.error.not.allowed"), ToastType.ERROR);
        return;
      }

      const isPhoneNumberResubscribe = isValidUSPhoneNumber(
        data.mainContact || data.additionalContact || "",
      );

      navigate(ROUTES_PATHS.PROFILE.RESUBSCRIBE, {
        state: {
          isPhoneNumberResubscribe,
          flag: true,
        },
      });
    } catch (e) {
      LoggingService.error("Error while resubscribe", e);
      navigate(ROUTES_PATHS.ERROR.INTERNAL);
    } finally {
      setIsLoading(false);
    }
  };

  return [resubscribe, isLoading] as const;
};

type SubscribedAlertsAPIPayload = {
  id: string;
  mainContact?: string;
  additionalContact?: string;
  captcha?: string;
};
