import { FC, useCallback, useEffect, useState, ChangeEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import { debounce } from "lodash";
import { Option } from "./Option";
import { useQueryZipCode, ZipCodeAPIResponse } from "./zipcodeinput.api";
import {
  getOptionLabel,
  isOptionSelected,
  formatZIPInput,
  RenderInputParams,
} from "./helper";
import { MyLocationIcon, CityValue, StyledInput } from "./components";

interface Props {
  onChange?: (zipCode: string) => void;
  defaultValue?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
}

export const ZipCodeInput: FC<Props> = ({
  onChange,
  defaultValue,
  helperText,
  error,
  disabled,
}) => {
  const { t } = useTranslation("layout");
  const [showCity, setShowCity] = useState(false);
  const [value, setValue] = useState<ZipCodeAPIResponse[number] | null>(null);
  const [options, setOptions] = useState<ZipCodeAPIResponse>([]);
  const { queryZipCode, loading } = useQueryZipCode(setOptions);
  const debouncedQuery = useCallback(
    debounce(
      (_, q: string, reason: AutocompleteInputChangeReason) =>
        reason === "input" && queryZipCode(q),
      500,
    ),
    [],
  );

  useEffect(() => {
    defaultValue &&
      queryZipCode(defaultValue).then((newOptions) => {
        if (!newOptions) return;

        const foundOption = newOptions.find(
          (option) => option.zipCode === defaultValue,
        );

        if (foundOption) {
          setValue(foundOption);
          setShowCity(true);
          return;
        }

        setValue({ zipCode: defaultValue, city: "" });
      });
  }, [defaultValue]);

  const handleChangeValue = (
    _: ChangeEvent<unknown>,
    newValue: ZipCodeAPIResponse[number] | string | null,
  ) => {
    if (!newValue) return;

    const setNewValue = (_value: ZipCodeAPIResponse[number]) => {
      setValue(_value);
      setShowCity(true);
    };

    if (typeof newValue === "string") {
      onChange && onChange(newValue);

      const option = options.find((element) => element.zipCode === newValue);

      option && setNewValue(option);
      return;
    }

    onChange && onChange(newValue.zipCode);
    setNewValue(newValue);
  };

  const handleChangeInput = (
    _: ChangeEvent<Record<string, unknown>>,
    q: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (value && reason === "reset") return;

    setShowCity(false);
    debouncedQuery(_, q, reason);
  };

  return (
    <Autocomplete
      id="zipCodeInput"
      options={options}
      renderOption={Option}
      style={{ position: "relative" }}
      filterOptions={(x) => x}
      disabled={disabled}
      renderInput={(params: RenderInputParams) => (
        <>
          <StyledInput
            {...params}
            variant="outlined"
            helperText={helperText}
            error={error}
            label={t("autocomplete.zip.code.label")}
            $hasStartAdornment
            inputProps={{
              ...params.inputProps,
              onChange: (e) => params.inputProps.onChange?.(formatZIPInput(e)),
              inputMode: "numeric",
            }}
            InputProps={{
              ...params.InputProps,
              className: "",
              startAdornment: (
                <InputAdornment position="start">
                  <RoomOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" data-testid="endAdornment">
                  <MyLocationIcon $featured={!!value} />
                </InputAdornment>
              ),
            }}
          />
          {showCity && value && <CityValue>{value.city}</CityValue>}
        </>
      )}
      onInputChange={handleChangeInput}
      onChange={handleChangeValue}
      value={value}
      loading={loading}
      getOptionSelected={isOptionSelected}
      getOptionLabel={getOptionLabel}
      getLimitTagsText={(more) => (
        <Trans t={t} values={{ more }} i18nKey="autocomplete.limit.tags" />
      )}
      clearText={t("autocomplete.clear")}
      closeText={t("autocomplete.close")}
      loadingText={t("autocomplete.loading")}
      noOptionsText={t("autocomplete.no.options")}
      openText={t("autocomplete.open")}
      openOnFocus
      freeSolo
      clearOnBlur={false}
      autoSelect
    />
  );
};
