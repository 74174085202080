import { FC } from "react";

export const CircleDefs: FC = () => (
  <svg height="150" width="400">
    <defs>
      <filter id="heatmapCircleFilter" x="0" y="0">
        <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
      </filter>
      <radialGradient
        id="heatmapCircleGradient"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
      >
        <stop
          offset="0%"
          style={{
            stopColor: "rgba(177, 231, 92, 0.8)",
            stopOpacity: 1,
          }}
        />
        <stop
          offset="100%"
          style={{
            stopColor: "rgba(94, 137, 160, 0.8)",
            stopOpacity: 1,
          }}
        />
      </radialGradient>
    </defs>
  </svg>
);
