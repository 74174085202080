import { SyntheticEvent, MouseEvent, FC } from "react";
import IconButton from "@material-ui/core/IconButton";
import { CloseIcon, StyledSnackbar } from "./components";
import { ToastType } from "../../../modules/App/ToastProvider/helper";
import { SnackbarContent } from "@material-ui/core";

interface Props {
  onClose: (_: SyntheticEvent | MouseEvent, reason?: string) => void;
  open: boolean;
  message: string;
  type?: ToastType;
}

export const Toast: FC<Props> = ({ onClose, open, message, type }) => (
  <StyledSnackbar
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    open={open}
    onClose={onClose}
    autoHideDuration={6000}
    $type={type || ToastType.SUCCESS}
  >
    <SnackbarContent
      message={message}
      action={
        <>
          <IconButton size="small" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </>
      }
    ></SnackbarContent>
  </StyledSnackbar>
);
