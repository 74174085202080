interface Response {
  alertId: string;
  source?: string;
  responseId: string;
  location?: string;
  isPaired: boolean;
}

class ResponseClass {
  KEY = "response";

  has() {
    return this.get() !== null;
  }

  get() {
    const data = localStorage.getItem(this.KEY);
    return data ? (JSON.parse(data) as Response) : null;
  }

  set(data: Response) {
    if (!data) {
      return;
    }
    return localStorage.setItem(this.KEY, JSON.stringify(data));
  }

  setIsPaired(isPaired: boolean) {
    const response = this.get();

    if (!response) {
      return;
    }

    this.set({ ...response, isPaired });
  }

  setZipCode(zipCode: string) {
    const response = this.get();

    if (!response) {
      return;
    }

    this.set({ ...response, location: zipCode });
  }

  clear() {
    localStorage.removeItem(this.KEY);
  }
}

export const ResponseService = new ResponseClass();
// Usage: ResponseService.has()
