import { useEffect, useState } from "react";
import { useProfile } from "../../../modules";
import { FlexApiService, LoggingService } from "../../services";

export const useSubscribedAlerts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscribedAlerts, setSubscribedAlerts] =
    useState<SubscribedAlertsState>({
      email: true,
      sms: true,
    });
  const { profile, error: profileError } = useProfile();

  const fetchSubscribedAlerts = async () => {
    if (!profile) return;

    try {
      setIsLoading(true);
      const response = await FlexApiService.get<SubscribedAlertsAPIResponse>(
        `subscriber/${profile.uuid}/alerts`,
      );

      if (response.status !== 200) {
        return;
      }

      const { alerts, alertTypes } = response.data;

      const emailAlertId = alertTypes.find(
        (alertType) => alertType.name === "EMAIL",
      )?.id;

      const smsAlertId = alertTypes.find(
        (alertType) => alertType.name === "SMS",
      )?.id;

      if (!smsAlertId || !emailAlertId) return;

      setSubscribedAlerts({
        email: !!alerts.find(
          (alert) => alert.subscriberAlertTypeId === emailAlertId,
        ),
        sms: !!alerts.find(
          (alert) => alert.subscriberAlertTypeId === smsAlertId,
        ),
      });
      setIsLoading(false);
    } catch (error) {
      LoggingService.error("Error while fetching user alerts: ", error);
    }
  };

  useEffect(() => {
    if (profileError) {
      setIsLoading(false);
      return;
    }
    if (!profile) return;

    fetchSubscribedAlerts();
  }, [profile?.uuid, profile?.additionalContact, profileError]);

  return [subscribedAlerts, isLoading] as const;
};

export enum SubscriberSyncStatus {
  PENDING = "PENDING",
  SUBSCRIBED = "SUBSCRIBED",
  SUBSCRIBED_SYNCED = "SUBSCRIBED_SYNCED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  UNSUBSCRIBED_SYNCED = "UNSUBSCRIBED_SYNCED",
}

type SubscribedAlertsState = {
  email: boolean;
  sms: boolean;
};

type SubscribedAlerts = {
  subscriberAlertTypeId: number;
  syncStatus: SubscriberSyncStatus;
  syncDate: string | null;
  createdDate: string | null;
}[];

type SubscribedAlertsAPIResponse = {
  alerts: SubscribedAlerts;
  alertTypes: {
    id: number;
    name: "EMAIL" | "SMS";
    createdDate: string | null;
  }[];
};
