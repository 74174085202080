import TextField from "@material-ui/core/TextField";
import styled, { css } from "styled-components";
import { COLORS } from "../../../colors";

interface InputProps {
  $hasStartAdornment?: boolean;
}

export const Input = styled(TextField).attrs({
  variant: "outlined",
})<InputProps>`
  & .MuiInputBase-root {
    background-color: ${COLORS.INPUT.BACKGROUND};
  }

  & .MuiOutlinedInput-input {
    ${(props) =>
      props.$hasStartAdornment
        ? css`
            padding: 25.5px 14px 12px 0;
          `
        : css`
            padding: 25.5px 16px 12px 16px;
          `}
  }

  & label.Mui-focused {
    color: ${COLORS.PRIMARY.DARK};
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
  }

  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(46px, 18px) scale(1);
  }

  & label.Mui-focused,
  & .MuiFormLabel-filled {
    ${(props) =>
      props.$hasStartAdornment
        ? css`
            transform: translate(46px, 8px) scale(1) !important;
          `
        : css`
            transform: translate(16px, 8px) scale(1) !important;
          `}
  }

  & .MuiInputLabel-outlined {
    ${(props) =>
      props.$hasStartAdornment
        ? css`
            transform: translate(46px, 20px) scale(1);
          `
        : css`
            transform: translate(16px, 20px) scale(1);
          `}

    font-family: Fira Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${COLORS.SECONDARY.LIGHT};

    &.Mui-disabled {
      color: ${COLORS.SECONDARY.DARK};
    }
  }

  & .MuiFormLabel-filled {
    color: ${COLORS.SECONDARY.MAIN};
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;

    &.Mui-disabled {
      color: ${COLORS.SECONDARY.MAIN};
    }
  }

  & .MuiInputBase-input {
    font-family: Fira Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: ${COLORS.FONT.DARK};

    &.Mui-disabled {
      color: ${COLORS.SECONDARY.DARK};
      font-weight: 400;
    }
  }

  & .PrivateNotchedOutline-legendNotched-5,
  .PrivateNotchedOutline-legendNotched-4 {
    max-width: 0;
  }

  & .MuiInput-underline:after {
    border-bottom-color: transparent;
  }

  & .MuiOutlinedInput-root {
    border-radius: 6px;

    & fieldset,
    &.Mui-disabled .MuiOutlinedInput-notchedOutline,
    &:hover fieldset,
    &.Mui-focused fieldset {
      border-color: ${COLORS.INPUT.BORDER};
    }

    &.Mui-error fieldset {
      border-color: ${COLORS.ERROR.MAIN};
    }

    fieldset legend {
      width: 0;
    }
  }

  div.Mui-disabled {
    background: #b6c5cc;
  }

  & .MuiFormHelperText-root.Mui-error {
    &.Mui-error {
      color: ${COLORS.ERROR.MAIN};
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.4000000059604645px;
      text-align: left;
    }
  }

  & .Mui-focused .MuiInputAdornment-positionEnd {
    color: ${COLORS.PRIMARY.MAIN};
  }

  & .MuiInputAdornment-root {
    color: ${COLORS.SECONDARY.LIGHT};
  }

  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${COLORS.FONT.DARK};
    -webkit-box-shadow: 0 0 0 30px ${COLORS.SECONDARY.ULTRALIGHT} inset !important;
  }
`;
