import { Link } from "react-router-dom";
import styled from "styled-components";
import { buttonStyles } from "./Button";

export const ButtonLink = styled(Link)`
  ${buttonStyles}
  display: block;
  width: fit-content;
  text-decoration: none;
  text-transform: uppercase;
`;
