import { FC, ReactNode, useState, SyntheticEvent, MouseEvent } from "react";
import { Toast } from "../../../common";
import { ToastContext } from "./context";
import { ToastItem, generateToastId, ToastType } from "./helper";

interface Props {
  children: ReactNode;
}

export const ToastProvider: FC<Props> = ({ children }) => {
  const [toasts, setToast] = useState<ToastItem[]>([]);

  const handleClose =
    (id: ToastItem["id"]) =>
    (_: SyntheticEvent | MouseEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setToast((prevState) => prevState.filter((toast) => toast.id !== id));
    };

  const showToast = (msg: ToastItem["message"], type?: ToastType) => {
    const newToastItem: ToastItem = {
      id: generateToastId(),
      message: msg,
      type,
    };
    setToast((prevState) => [...prevState, newToastItem]);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          open={true}
          message={toast.message}
          type={toast.type}
          onClose={handleClose(toast.id)}
        />
      ))}
    </ToastContext.Provider>
  );
};
