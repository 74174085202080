import axios, { AxiosInstance, AxiosResponse } from "axios";
import { id, joinSignature, SigningKey } from "ethers/lib/utils";
import { LoggingService } from "./logging";
import { isBrowserPaired } from "../utils";
import { PrivateExtendedKeyService } from "./privateExtendedKey";
import { PublicAddressService } from "./publicAddress";

type RequestMethod = "GET" | "POST" | "PUT";

class RequestClass {
  AXIOS_CLIENT: AxiosInstance;
  URL: string;
  addAuthorizationHeader = true;

  constructor(baseURL: string) {
    const axiosClient = axios.create({
      baseURL,
    });

    this.URL = baseURL;
    this.AXIOS_CLIENT = axiosClient;
  }

  private async runRequest<ResponseType, DataType = undefined>(
    method: RequestMethod,
    url: string,
    data?: DataType,
    appendAuthorizationHeader?: boolean,
  ) {
    LoggingService.info(
      `[FLEX API] Request: to ${url}(${method}), data: `,
      data,
    );
    try {
      const headers: Record<string, string> = {};

      // Add authentication signature if browser is paired
      if (
        isBrowserPaired() &&
        (appendAuthorizationHeader || this.addAuthorizationHeader)
      ) {
        const { signature, publicAddress } = this.createRequestSignature();

        headers.Authorization = signature;
        headers["public-address"] = publicAddress;
      }

      const response = (await this.AXIOS_CLIENT({
        method,
        url,
        data,
        headers,
      })) as AxiosResponse<ResponseType>;

      if (response.status >= 400) {
        LoggingService.error(`[FLEX API] Error: ${response.statusText}`, data);
      }

      return response;
    } catch (error) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response) {
        return error.response as AxiosResponse<ResponseType>;
      }

      throw new Error(error);
    }
  }

  disableAuthorizationHeader() {
    this.addAuthorizationHeader = false;
  }

  createRequestSignature() {
    const publicAddress = PublicAddressService.get() as string;
    const utf8BytesData = id(publicAddress);
    const signingKey = new SigningKey(
      PrivateExtendedKeyService.getKeyPair?.privateKey as string,
    );
    const signature = joinSignature(signingKey.signDigest(utf8BytesData));

    return { signature, publicAddress };
  }

  async get<ResponseType, DataType = undefined>(
    url: string,
    data?: DataType,
    appendAuthorizationHeader?: boolean,
  ) {
    return this.runRequest<ResponseType, DataType>(
      "GET",
      url,
      data,
      appendAuthorizationHeader,
    );
  }

  async post<ResponseType, DataType = undefined>(
    url: string,
    data?: DataType,
    appendAuthorizationHeader?: boolean,
  ) {
    return this.runRequest<ResponseType, DataType>(
      "POST",
      url,
      data,
      appendAuthorizationHeader,
    );
  }

  async put<ResponseType, DataType = undefined>(
    url: string,
    data?: DataType,
    appendAuthorizationHeader?: boolean,
  ) {
    return this.runRequest<ResponseType, DataType>(
      "PUT",
      url,
      data,
      appendAuthorizationHeader,
    );
  }
}

export const FlexApiService = new RequestClass(
  process.env.REACT_APP_FLEX_API_URL as string,
);
// Usage: FlexApiService.get<Data>("/todos/1").then((data) => console.log(data));
