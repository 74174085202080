export const ROUTES_PATHS = {
  MAIN: "/",
  REGISTRATION: {
    MAIN: "/register",
    SENT: "/register/sent",
    SUCCESS: "/register/success",
    INVALID: "/register/invalid",
  },
  PAIR: {
    MAIN: "/sign-in",
    SENT: "/sign-in/sent",
    INVALID: "/sign-in/invalid",
    CONFIRM: "/sign-in/confirm",
    SUCCESS: "/sign-in/success",
  },
  CONFIRM: {
    MAIN: "/t",
  },
  PROFILE: {
    MAIN: "/profile",
    CONFIRM: "/profile/confirm",
    RESUBSCRIBE: "/profile/resubscribe",
  },
  EVENT: {
    MAIN: "/event",
    ALERT: "/event/:alertId",
    INVALID: "/event/invalid",
  },
  ERROR: {
    MAIN: "/error",
    INTERNAL: "/error/500",
    NOT_FOUND: "/error/404",
    UNAUTHORIZED: "/error/unauthorized",
  },
  MAINTENANCE: {
    MAIN: "/maintenance",
  },
} as const;
