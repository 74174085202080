import styled, { css } from "styled-components";
import { COLORS } from "../../colors";
import keyIconSrc from "./key_icon.svg";
import { Body2 } from "../Typography";

interface OpenProps {
  $open: boolean;
}

export const Wrapper = styled.section<OpenProps>`
  position: relative;

  ${(props) =>
    props.$open &&
    css`
      ${Button} {
        color: ${COLORS.PRIMARY.DARK};
        filter: drop-shadow(0px 0px 8px rgba(84, 110, 122, 0.6));
      }

      ${OverlayWrapper} {
        visibility: visible;
        opacity: 1;
      }
    `}
`;

export const KeyIcon = styled.img.attrs({
  src: keyIconSrc,
})`
  margin-bottom: 30px;
`;

export const Button = styled.button`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;

  background: transparent;
  border: none;
  cursor: pointer;

  color: ${COLORS.SECONDARY.MAIN};
  text-decoration: none;

  :hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 500px) {
    margin-left: 5px;
    margin-right: 10px;
  }

  @media (max-width: 590px) {
    margin: 8px 0;
  }
`;

export const OverlayWrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1000;
  left: 0;
  bottom: 42px;
  width: max-content;
  max-width: calc(100vw - 32px);
  padding: 30px;
  background: ${COLORS.BACKGROUND};
  border: 1px solid #cfd8dc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 19px;
  filter: none;

  transition: all 0.1s linear;

  text-align: center;
  & > * {
    margin: 0 auto;
    text-align: center;
  }

  ${Body2} {
    max-width: 261px;
  }

  & > :not(:first-child) {
    margin-bottom: 15px;
  }

  & > :last-child {
    margin-bottom: 0;
    max-width: 261px;
  }

  @media (max-width: 640px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Token = styled.div`
  background: #eceff1;
  border-radius: 6px;
  width: 100%;
  padding: 8px;
  word-wrap: break-word;
  color: #637b2a;
  max-width: 261px;
  text-align: center;

  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
`;
