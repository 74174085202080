import styled, { css } from "styled-components";
import { COLORS } from "../../colors";
import { Button } from "../Button";
import { Input } from "../Form";
import { Body, Body2, SubTitle1, H6, captionStyle } from "../Typography";
import { Wrapper as Loader } from "../Loader/components";

export const Wrapper = styled.form`
  width: 480px;
  height: 100%;
  padding: 30px 20px 20px;

  display: flex;
  flex-direction: column;

  > ${Button} {
    margin-top: auto;
    width: 100%;
  }

  ${Loader} {
    svg {
      height: auto !important;
      width: 100% !important;
    }
  }

  @media (max-width: 480px) {
    width: 100vw;
  }
`;

export const Header = styled.header`
  border-bottom: 1px solid ${COLORS.FONT.DARK};
  padding-bottom: 20px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > ${Button} {
    color: ${COLORS.FONT.DARK};
  }
`;

export const Main = styled.main`
  margin-bottom: 20px;

  ${H6} {
    margin-bottom: 10px;
  }
`;

export const Section = styled.div`
  margin-bottom: 30px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

interface ValueWrapperProps {
  withInput?: boolean;
}

export const ValueWrapper = styled.div<ValueWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    !props.withInput &&
    css`
      > div {
        margin-left: auto;
      }
    `}

  ${(props) =>
    props.withInput &&
    css`
      flex-wrap: nowrap;
      > div:nth-child(2) {
        margin-left: 15px;
      }
    `}

  margin-top: 5px;

  button.MuiButton-contained {
    padding: 12px 15px;
    background: ${COLORS.SECONDARY.LIGHT};
    color: ${COLORS.FONT.LIGHT};
    border: none;

    :hover {
      background: ${COLORS.SECONDARY.MAIN};
    }

    :disabled {
      background: ${COLORS.SURFACE};
      color: ${COLORS.SECONDARY.LIGHT};
    }
  }

  .MuiIconButton-root:hover {
    background: transparent;
  }

  .MuiIconButton-root {
    position: relative;
  }

  ${Input} {
    width: 100%;
  }

  ${Body} {
    max-width: 266px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
  }

  @media (max-width: 455px) {
    ${Button} {
      margin-top: 8px;
    }
  }
`;

export const Value = styled(SubTitle1)`
  color: ${COLORS.PRIMARY.MAIN};
  padding-left: 15px;
  overflow-wrap: anywhere;
  line-height: 48px;
`;

export const Message = styled(Body2)`
  padding: 0 16px;
  padding-top: 10px;

  span {
    display: block;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-align: left;
    color: ${COLORS.PRIMARY.MAIN};
    margin-bottom: 10px;
  }
`;

interface PopoverProps {
  open: boolean;
}

export const Popover = styled.div<PopoverProps>`
  background: ${COLORS.BACKGROUND};
  border: 1px solid ${COLORS.SURFACE};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 15px;
  padding-right: 45px;
  width: 300px;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  ${(props) =>
    props.open &&
    css`
      visibility: visible;
      opacity: 1;
      transition: opacity linear 0.2s;
    `}
  will-change: visibility, opacity;
  transition: opacity linear 0.2s;

  p {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    padding: 0;
    color: ${COLORS.FONT.DARK};
  }

  svg {
    position: absolute;
    right: 11px;
    top: 11px;
  }
`;

export const ContactText = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

  color: ${COLORS.SECONDARY.DARK};
  margin-bottom: 0;

  a {
    color: inherit;

    :hover {
      text-decoration: none;
    }
  }
`;

export const ModalLink = styled.button`
  background: transparent;
  border: none;
  color: ${COLORS.PRIMARY.MAIN};
  ${captionStyle};
`;

export const SettingsButton = styled(Button)`
  background-color: transparent;
  color: rgba(0, 0, 0, 0.54);

  &:hover {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.64);
    font-weight: 500;
  }
`;

export const SettingsButtonLabel = styled.h6`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1.1px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  width: 75px;
  padding-left: 10px;

  @media (max-width: 480px) {
    display: none;
  }
`;
