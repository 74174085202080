import styled, { css } from "styled-components";
import { lighten } from "@material-ui/core";
import { ReactComponent as MapComponent } from "./california_map.svg";
import { COLORS } from "../..";

export const Wrapper = styled.section`
  max-width: 551px;
  width: 100%;
  max-height: 410px;
  padding: 30px;

  background: ${COLORS.BACKGROUND};

  border: 1px solid ${COLORS.SURFACE};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 19px;
`;

interface MapProps {
  $data: {
    county: string;
    ratio: number;
  }[];
}

export const Map = styled(MapComponent)<MapProps>`
  height: 100%;
  max-height: 271px;
  margin: 0 auto;

  ${(props) =>
    props.$data.map(
      (item) => css`
        #${item.county.replaceAll(" ", "_")} {
          fill: ${lighten("#0B3B51", item.ratio)};
          stroke: ${lighten("#0B3B51", item.ratio)};
        }
      `,
    )}
`;

export const MapContainer = styled.div`
  height: 100%;
  max-height: 318px;
  display: flex;
  padding-top: 38px;
  align-items: center;
`;

export const LegendContainer = styled.div`
  width: 61px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  svg {
    max-height: 182px;
    border-radius: 100px;
  }
`;

export const LegendTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  color: ${COLORS.SECONDARY.MAIN};

  margin: 0;
  padding: 0;
`;
