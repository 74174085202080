import { isValidPhoneNumber } from "libphonenumber-js";
import { queryZipCode } from "./utils";

export const REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const REGEX_ZIP = /^\d{5}$/;

export const isValidZIPCode = (zipCode?: string | number) => {
  const ALLOWED_ZIP_CODES_FROM_NEVADA = [
    89003, 89019, 89041, 89048, 89060, 89061,
  ];

  if (!zipCode) {
    return false;
  }

  // Cast to number
  if (typeof zipCode === "string") {
    zipCode = +zipCode;
  }

  if (ALLOWED_ZIP_CODES_FROM_NEVADA.includes(zipCode)) {
    return true;
  }

  return zipCode >= 90001 && zipCode <= 96162;
};

export const isExistsZIPCode = async (zipCode?: string | number) => {
  if (!isValidZIPCode(zipCode)) {
    return false;
  }

  try {
    const data = await queryZipCode(`${zipCode}`);

    if (!data) return false;

    return !!data.find((items) => items.zipCode === zipCode);
  } catch {
    return false;
  }
};

const region = ["production", "staging"].includes(
  `${process.env.REACT_APP_STAGE}`,
)
  ? "US"
  : undefined;

export const isValidUSPhoneNumber = (value: string) =>
  isValidPhoneNumber(value, region);
