import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Checkbox, Caption } from "../../../common";
import { ConsentsWrapper, Consent } from "./components";
import { RegistrationFormValues, Tabs } from "./helpers";

interface Props {
  control: Control<RegistrationFormValues>;
  tab: Tabs;
}

export const Consents: FC<Props> = ({ control, tab }) => {
  const { t } = useTranslation("registration_page");
  return (
    <ConsentsWrapper>
      {CONSENTS[tab].map((consent, index) => (
        <Consent key={consent.i18nKey}>
          <FormControlLabel
            control={
              <Controller
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    inputRef={field.ref}
                    ref={undefined}
                    checked={field.value}
                    size={"small"}
                  />
                )}
                name={`${
                  tab === Tabs.EMAIL ? "consentEmail" : "consentSms"
                }.${index}`}
                control={control}
                defaultValue={false}
                rules={{
                  required: true,
                }}
              />
            }
            label={
              <Caption>
                <Trans
                  t={t}
                  i18nKey={consent.i18nKey}
                  values={{
                    messagesPerMonth: process.env
                      .REACT_APP_SMS_PER_MONTH as string,
                    helpShortCode: process.env
                      .REACT_APP_HELP_SHORT_CODE as string,
                    stopShortCode: process.env
                      .REACT_APP_STOP_SHORT_CODE as string,
                  }}
                  components={{
                    terms: (
                      <a
                        href={process.env.REACT_APP_TERMS_URL as string}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      />
                    ),
                    privacy: (
                      <a
                        href={process.env.REACT_APP_PRIVACY_URL as string}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      />
                    ),
                    b: <b />,
                  }}
                />
              </Caption>
            }
          />
        </Consent>
      ))}
    </ConsentsWrapper>
  );
};

const CONSENTS = {
  [Tabs.EMAIL]: [
    {
      i18nKey: "consent.email.1",
    },
    {
      i18nKey: "consent.email.2",
    },
  ],
  [Tabs.SMS]: [
    {
      i18nKey: "consent.sms.1",
    },
    {
      i18nKey: "consent.sms.2",
    },
  ],
} as const;
