import styled from "styled-components";
import { COLORS } from "../../colors";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  flex: 1;

  svg {
    max-width: 600px;
    width: 30% !important;
  }

  @media (max-width: 1100px) {
    svg {
      width: 50% !important;
    }
  }

  @media (max-width: 600px) {
    svg {
      width: 70% !important;
    }
  }

  p {
    color: ${COLORS.SECONDARY.LIGHT};
  }
`;
