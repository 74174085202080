import styled, { css } from "styled-components";
import { COLORS } from "../../colors";

import backgroundSrc from "./background.png";

export const Wrapper = styled.section`
  position: relative;
  left: 0;
  margin-top: auto;

  min-height: 460px;

  overflow: hidden;
  user-select: none;

  background: url(${backgroundSrc}) no-repeat center;
  background-size: cover;
`;

export const Box = () => (
  <svg viewBox="0 0 705 197" preserveAspectRatio="none">
    <path opacity="0.5" d="M705 0H0V197H592.124L705 0Z" />
  </svg>
);

const BoxCommonStyles = css`
  position: absolute;
  left: 0;
  height: 77%;
  width: 49%;
  z-index: 0;

  svg {
    height: 100%;
    width: 100%;
  }

  @media (max-width: 1280px) {
    width: 60%;
  }

  @media (max-width: 890px) {
    width: 70%;
  }

  @media (max-width: 770px) {
    width: 80%;
  }

  @media (max-width: 620px) {
    width: 100%;
  }
`;

export const TopBox = styled.div.attrs({
  children: <Box />,
})`
  path {
    fill: ${COLORS.SECONDARY.LIGHT};
  }
  top: 0;
  ${BoxCommonStyles}
`;

export const BottomBox = styled.div.attrs({
  children: <Box />,
})`
  path {
    fill: ${COLORS.SECONDARY.MAIN};
  }
  transform: scale(1, -1);
  bottom: 0;
  ${BoxCommonStyles}
`;

export const TextWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 16px;

  @media (min-width: 1280px) {
    width: 1280px;
  }

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (min-width: 600px) {
    padding: 0 24px;
  }
`;

export const Text = styled.a`
  position: relative;
  font-family: "Fira Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 0;
  color: ${COLORS.FONT.LIGHT};
  text-decoration: none;

  span {
    color: ${COLORS.FONT.LIGHT};
    text-decoration: none;
  }

  svg {
    position: absolute;
    width: 48px;
    height: 48px;
    margin-left: 8px;
    bottom: -2px;
  }

  @media (max-width: 450px) {
    font-size: 24px;
    max-width: 274px;

    svg {
      width: 32px;
      height: 32px;
      margin-left: 6px;
    }
  }
`;
