import { ProfileUsernameType } from "../modules/App/ProfileProvider/context";
import { isValidUSPhoneNumber } from "./formRules";
import {
  PublicAddressService,
  PrivateExtendedKeyService,
  FlexApiService,
  LoggingService,
} from "./services";

export const isBrowserPaired = () => {
  return PublicAddressService.has() && PrivateExtendedKeyService.has();
};

export const wipeSubscriberData = () => {
  PublicAddressService.clear();
  PrivateExtendedKeyService.clear();
};

export const getUsernameType = (username: string) => {
  if (isValidUSPhoneNumber(username)) {
    return ProfileUsernameType.MOBILE_NUMBER;
  }

  return ProfileUsernameType.EMAIL;
};

export const queryZipCode = async (zipCode: string) => {
  try {
    const response = await FlexApiService.get<
      ZipCodeAPIResponse,
      ZipCodeAPIPayload
    >(`geolocation?q=${zipCode}`);

    switch (response.status) {
      case 200:
        return response.data;
    }
  } catch (error) {
    LoggingService.error("Error while query zip code: ", error);
  }
  return null;
};
type ZipCodeAPIResponse = {
  zipCode: string;
  city: string;
  latitude: number;
  longitude: number;
  county: string;
}[];
type ZipCodeAPIPayload = never;

export const capitalizeFirstLetter = (val: string) => {
  return val.charAt(0).toUpperCase() + val.slice(1);
};
