export const footerLinks = [
  {
    type: "HREF",
    title: "footer.link.tos",
    href: process.env.REACT_APP_TERMS_URL,
  },
  {
    type: "HREF",
    title: "footer.link.policy",
    href: process.env.REACT_APP_PRIVACY_URL as string,
  },
  {
    type: "HREF",
    title: "footer.link.contact",
    href: process.env.REACT_APP_CONTACT_US as string,
  },
] as const;
