import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PublicAddressService } from "../../services/publicAddress";
import { SubTitle1, Body2 } from "../Typography";
import { Wrapper, KeyIcon, OverlayWrapper, Button, Token } from "./components";

export const Identity: FC = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation("layout");
  const [open, setOpen] = useState(false);

  const hasIdentity = PublicAddressService.has();

  const handleClick = () => setOpen((o) => !o);

  useEffect(() => {
    const handleWindowClick = (event: MouseEvent) => {
      const eventPath = event.composedPath();

      if (wrapperRef.current && !eventPath.includes(wrapperRef.current)) {
        setOpen(false);
      }
    };

    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  if (!hasIdentity) {
    return null;
  }

  return (
    <Wrapper $open={open} ref={wrapperRef}>
      <Button onClick={handleClick} data-testid="did-button">
        Secured with DID
      </Button>

      <OverlayWrapper data-testid="did-overlay">
        <KeyIcon alt={t("footer.did.icon.alt")} />
        <SubTitle1>{t("footer.did.subtitle")}</SubTitle1>
        <Body2>{t("footer.did.body")}</Body2>
        <Token data-testid="did-token">{PublicAddressService.getDID()}</Token>
      </OverlayWrapper>
    </Wrapper>
  );
};
