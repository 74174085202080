import { FC, ReactNode, useState } from "react";
import { Position } from "../../../common";
import { MapContext } from "./context";

interface Props {
  children: ReactNode;
}

export const MapProvider: FC<Props> = ({ children }) => {
  const [pin, setPin] = useState<Position | undefined>(undefined);

  const clearPin = () => {
    setPin(undefined);
  };

  return (
    <MapContext.Provider value={{ pin, setPin, clearPin }}>
      {children}
    </MapContext.Provider>
  );
};
