import { PartialRouteObject, Navigate } from "react-router";
import { ROUTES_PATHS } from "./routesPaths";
import { pageWithMeta } from "../../../common";

// Modules
import {
  LazyRegistration,
  LazyRegistrationSent,
  LazyRegistrationSignUp,
  LazyRegistrationSuccess,
  LazyRegistrationTokenInvalid,
  LazyRegistrationConfirm,
  LazyError,
  LazyErrorInternal,
  LazyErrorNotFound,
  LazyErrorUnauthorized,
  LazyMaintenance,
  LazyRegistrationResubscribeSent,
  LazyEvent,
  LazyEventSuccessfulParticipation,
  LazyEventInvalid,
} from "../../index";

const stripParent = (parentPath: string, childPath: string): string => {
  if (!childPath.startsWith(parentPath)) {
    throw new Error("Parent path mismatch");
  }
  const result = childPath.slice(parentPath.length);
  return result.startsWith("/") ? result.slice(1) : result;
};

const TemporaryIndexRedirect = () => {
  window.location.replace(
    process.env.REACT_APP_INDEX_REDIRECT || "https://www.flexalert.org/",
  );
  return null;
};

export const routesComponents: PartialRouteObject[] = [
  {
    path: ROUTES_PATHS.MAIN,
    element: <TemporaryIndexRedirect />,
  },
  {
    path: stripParent(ROUTES_PATHS.MAIN, ROUTES_PATHS.REGISTRATION.MAIN),
    element: <LazyRegistration />,
    children: [
      {
        path: "/",
        element: pageWithMeta(<LazyRegistrationSignUp />, {
          titleI18nKey: "signup.meta.title",
          descI18nKey: "signup.meta.desc",
          nameSpace: "registration_page",
        }),
      },
      {
        path: stripParent(
          ROUTES_PATHS.REGISTRATION.MAIN,
          ROUTES_PATHS.REGISTRATION.SENT,
        ),
        element: pageWithMeta(<LazyRegistrationSent />, {
          titleI18nKey: "sent.meta.title",
          descI18nKey: "sent.meta.desc",
          nameSpace: "registration_page",
        }),
      },
      {
        path: stripParent(
          ROUTES_PATHS.REGISTRATION.MAIN,
          ROUTES_PATHS.REGISTRATION.INVALID,
        ),
        element: pageWithMeta(<LazyRegistrationTokenInvalid />, {
          titleI18nKey: "invalid.meta.title",
          descI18nKey: "invalid.meta.desc",
          nameSpace: "registration_page",
        }),
      },
      {
        path: stripParent(
          ROUTES_PATHS.REGISTRATION.MAIN,
          ROUTES_PATHS.REGISTRATION.SUCCESS,
        ),
        element: pageWithMeta(<LazyRegistrationSuccess />, {
          titleI18nKey: "subscribed.meta.title",
          descI18nKey: "subscribed.meta.desc",
          nameSpace: "registration_page",
        }),
      },
    ],
  },
  {
    path: stripParent(ROUTES_PATHS.MAIN, ROUTES_PATHS.CONFIRM.MAIN),
    element: pageWithMeta(<LazyRegistrationConfirm />, {
      titleI18nKey: "signup.meta.title",
      descI18nKey: "signup.meta.desc",
      nameSpace: "registration_page",
    }),
  },
  {
    path: stripParent(ROUTES_PATHS.MAIN, ROUTES_PATHS.PAIR.MAIN),
    element: <LazyRegistration />,
    children: [
      {
        path: "/",
        element: pageWithMeta(<LazyRegistrationSignUp />, {
          titleI18nKey: "pair.meta.title",
          descI18nKey: "pair.meta.desc",
          nameSpace: "registration_page",
        }),
      },
      {
        path: stripParent(ROUTES_PATHS.PAIR.MAIN, ROUTES_PATHS.PAIR.SENT),
        element: pageWithMeta(<LazyRegistrationSent />, {
          titleI18nKey: "sent.pair.meta.title",
          descI18nKey: "sent.pair.meta.desc",
          nameSpace: "registration_page",
        }),
      },
      {
        path: stripParent(ROUTES_PATHS.PAIR.MAIN, ROUTES_PATHS.PAIR.INVALID),
        element: pageWithMeta(<LazyRegistrationTokenInvalid />, {
          titleI18nKey: "invalid.pair.meta.title",
          descI18nKey: "invalid.pair.meta.desc",
          nameSpace: "registration_page",
        }),
      },
      {
        path: stripParent(ROUTES_PATHS.PAIR.MAIN, ROUTES_PATHS.PAIR.CONFIRM),
        element: pageWithMeta(<LazyRegistrationConfirm />, {
          titleI18nKey: "pair.meta.title",
          descI18nKey: "pair.meta.desc",
          nameSpace: "registration_page",
        }),
      },
      {
        path: stripParent(ROUTES_PATHS.PAIR.MAIN, ROUTES_PATHS.PAIR.SUCCESS),
        element: pageWithMeta(<LazyRegistrationSuccess />, {
          titleI18nKey: "subscribed.pair.meta.title",
          descI18nKey: "subscribed.pair.meta.desc",
          nameSpace: "registration_page",
        }),
      },
    ],
  },
  {
    path: stripParent(ROUTES_PATHS.MAIN, ROUTES_PATHS.PROFILE.MAIN),
    element: <LazyRegistration />,
    children: [
      {
        path: stripParent(
          ROUTES_PATHS.PROFILE.MAIN,
          ROUTES_PATHS.PROFILE.RESUBSCRIBE,
        ),
        element: pageWithMeta(<LazyRegistrationResubscribeSent />, {
          titleI18nKey: "resubscribe.meta.title",
          descI18nKey: "resubscribe.meta.desc",
          nameSpace: "registration_page",
        }),
      },
    ],
  },
  {
    path: stripParent(ROUTES_PATHS.MAIN, ROUTES_PATHS.EVENT.MAIN),
    element: <LazyEvent />,
    children: [
      {
        path: "/",
        element: <Navigate to={ROUTES_PATHS.ERROR.NOT_FOUND} replace />,
      },
      {
        path: stripParent(ROUTES_PATHS.EVENT.MAIN, ROUTES_PATHS.EVENT.ALERT),
        element: pageWithMeta(<LazyEventSuccessfulParticipation />, {
          titleI18nKey: "success.default.meta.title",
          descI18nKey: "success.default.meta.desc",
          nameSpace: "event_page",
        }),
      },
      {
        path: stripParent(ROUTES_PATHS.EVENT.MAIN, ROUTES_PATHS.EVENT.INVALID),
        element: pageWithMeta(<LazyEventInvalid />, {
          titleI18nKey: "invalid.meta.title",
          descI18nKey: "invalid.meta.desc",
          nameSpace: "event_page",
        }),
      },
    ],
  },
  {
    path: stripParent(ROUTES_PATHS.MAIN, ROUTES_PATHS.ERROR.MAIN),
    element: <LazyError />,
    children: [
      {
        path: "/",
        element: <Navigate to={ROUTES_PATHS.ERROR.NOT_FOUND} replace={true} />,
      },
      {
        path: stripParent(ROUTES_PATHS.ERROR.MAIN, ROUTES_PATHS.ERROR.INTERNAL),
        element: pageWithMeta(<LazyErrorInternal />, {
          titleI18nKey: "500.meta.title",
          descI18nKey: "500.meta.desc",
          nameSpace: "error_page",
        }),
      },
      {
        path: stripParent(
          ROUTES_PATHS.ERROR.MAIN,
          ROUTES_PATHS.ERROR.NOT_FOUND,
        ),
        element: pageWithMeta(<LazyErrorNotFound />, {
          titleI18nKey: "404.meta.title",
          descI18nKey: "404.meta.desc",
          nameSpace: "error_page",
        }),
      },
      {
        path: stripParent(
          ROUTES_PATHS.ERROR.MAIN,
          ROUTES_PATHS.ERROR.UNAUTHORIZED,
        ),
        element: pageWithMeta(<LazyErrorUnauthorized />, {
          titleI18nKey: "unauthorized.meta.title",
          descI18nKey: "unauthorized.meta.desc",
          nameSpace: "error_page",
        }),
      },
    ],
  },
  {
    path: stripParent(ROUTES_PATHS.MAIN, ROUTES_PATHS.MAINTENANCE.MAIN),
    element: pageWithMeta(<LazyMaintenance />, {
      titleI18nKey: "maintenance.meta.title",
      descI18nKey: "maintenance.meta.desc",
      nameSpace: "error_page",
    }),
  },
  {
    path: "/*",
    element: <Navigate to={ROUTES_PATHS.ERROR.NOT_FOUND} replace={true} />,
  },
];
