import { useState, FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { toWords } from "number-to-words";
import { ButtonText } from "../../Button";
import { ModalLink, Container, ModalWrapper } from "./components";

export const SubscriptionInfo: FC = () => {
  const { t } = useTranslation("settings");
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen((s) => !s);

  const isPlural = SUBSCRIPTION_DELAY > 1;
  const subscriptionDelayInWords = toWords(SUBSCRIPTION_DELAY);

  return (
    <Container>
      <ModalLink open={open} type="button" onClick={toggleOpen}>
        {t("unsubscribe.info.open.button")}
      </ModalLink>
      <ModalWrapper open={open} data-testid="subscription-info-modal">
        <p>
          <Trans
            t={t}
            i18nKey="unsubscribe.info.email"
            values={{
              pluralForm: isPlural ? "s" : "",
              delay: subscriptionDelayInWords,
            }}
            components={{
              a: (
                <a
                  href={process.env.REACT_APP_CONTACT_US as string}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                />
              ),
            }}
          />
        </p>
        <p>
          <Trans
            t={t}
            i18nKey="unsubscribe.info.sms"
            components={{
              b: <b />,
            }}
            values={{
              messagesPerMonth: process.env.REACT_APP_SMS_PER_MONTH,
              helpShortCode: process.env.REACT_APP_HELP_SHORT_CODE,
              stopShortCode: process.env.REACT_APP_STOP_SHORT_CODE,
              delay: subscriptionDelayInWords,
              pluralForm: isPlural ? "s" : "",
            }}
          />
        </p>
        <ButtonText type="button" onClick={toggleOpen}>
          {t("unsubscribe.info.close.button")}
        </ButtonText>
      </ModalWrapper>
    </Container>
  );
};

const SUBSCRIPTION_DELAY = +(process.env.REACT_APP_SUBSCRIPTION_DELAY || 0);
