import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";
import { ROUTES_PATHS } from "../../modules";
import {
  FlexApiService,
  isValidUSPhoneNumber,
  LoggingService,
  ResponseService,
  useCaptcha,
} from "..";

export const usePairUsername = (alertId?: string) => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useCaptcha();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (
    data: Omit<PairAPIPayload, "captcha" | "responseId" | "alertId"> | null,
  ) => {
    if (!data) {
      LoggingService.warning("Pair: Username was not provided.");
      return;
    }

    if (!executeRecaptcha) {
      LoggingService.warning("reCAPTCHA is not available");
      return;
    }

    const isPhoneNumberRegistration = isValidUSPhoneNumber(data.username);

    try {
      setIsLoading(true);

      const parsedData = { username: data.username };
      const responseData: { alertId?: string; responseId?: string } = {};

      if (isPhoneNumberRegistration) {
        parsedData.username = parsePhoneNumber(data.username).format("E.164");
      }

      if (alertId) {
        responseData.alertId = alertId;
        responseData.responseId = ResponseService.get()?.responseId;
      }

      const captchaToken = await executeRecaptcha();
      const response = await FlexApiService.post<
        PairAPIResponse,
        PairAPIPayload
      >("subscriber/signin", {
        ...parsedData,
        ...responseData,
        captcha: captchaToken === "" ? undefined : captchaToken,
      });
      setIsLoading(false);

      switch (response.status) {
        // 201 - Success. Pairing link was sent to user's email address
        // 404 - Email address doesn't exist
        // 409 - User have currently assigned active token. Please wait until old expires
        case 409:
          navigate(ROUTES_PATHS.PAIR.INVALID, {
            state: { expirationDate: response.data.expirationDate, flag: true },
          });
          break;
        case 404:
        case 201:
          navigate(ROUTES_PATHS.PAIR.SENT, {
            state: { isPhoneNumberRegistration, flag: true },
          });
          break;

        default:
          navigate(ROUTES_PATHS.ERROR.INTERNAL);
          break;
      }
    } catch (error) {
      LoggingService.error("Pair error: ", error);
      navigate(ROUTES_PATHS.ERROR.INTERNAL);
    }
  };

  return { onSubmit, isLoading };
};

type PairAPIPayload = {
  captcha?: string;
  username: string;
  responseId?: string;
  alertId?: string;
};

type PairAPIResponse = {
  expirationDate?: string;
};
