import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FlexApiService,
  isBrowserPaired,
  LoggingService,
  ResponseService,
} from "..";
import { ROUTES_PATHS } from "../../modules";
import { signResponse } from "../../modules/Event/SuccessfulParticipation/helpers";

export const usePairResponse = (ignoreErrors = false) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleConflictResponse = (response: SendPairResponseResponse) => {
    if (!response?.alertId || !response.responseId) {
      return;
    }

    ResponseService.set({
      alertId: response.alertId,
      responseId: response.responseId,
      source:
        response.sourceCode === "unknown" ? undefined : response.sourceCode,
      location: response.zipCode === null ? undefined : response.zipCode,
      isPaired: true,
    });
  };

  const sendPairResponse = async (alertId: string) => {
    try {
      setIsLoading(true);

      if (!isBrowserPaired()) {
        return;
      }

      const eventResponse = ResponseService.get();

      // Response not saved
      if (!eventResponse) {
        return;
      }

      const {
        isPaired,
        responseId,
        alertId: savedAlertId,
        source,
        location,
      } = eventResponse;

      if (alertId !== savedAlertId) {
        return;
      }

      if (isPaired || !responseId) {
        return;
      }

      const signature = signResponse(alertId, source, location);

      const response = await FlexApiService.post<
        SendPairResponseResponse,
        SendPairResponsePayload
      >("response/pair", { signature, responseId: `${responseId}` });

      switch (response.status) {
        // 201 - Response paired with subscriber
        // 409 - Response already paired with subscriber
        // 404 - Response not found
        case 201:
        case 404:
          ResponseService.setIsPaired(true);
          return;
        case 409:
          handleConflictResponse(response.data);
          return;
        // 401 - Signature is not valid (headers or body)
        case 401:
          if (!ignoreErrors) navigate(ROUTES_PATHS.ERROR.UNAUTHORIZED);
          return;
      }
    } catch (error) {
      LoggingService.error("Error while sending pair response: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return [sendPairResponse, isLoading] as const;
};

type SendPairResponsePayload = {
  responseId: string;
  signature: string;
};

type SendPairResponseResponse = {
  responseId: string;
  zipCode: string | null;
  sourceCode: string | "unknown";
  alertId: string;
} | null;
