import layout from "./layout.json";
import registration_page from "./registration-page.json";
import error_page from "./error-page.json";
import event_page from "./event-page.json";
import zip_form from "./zipcode-form.json";
import settings from "./settings.json";

export const enNamespaces = {
  registration_page,
  layout,
  error_page,
  event_page,
  zip_form,
  settings,
};
