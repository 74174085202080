import { ElementType, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm, Controller } from "react-hook-form";
import { useTranslation, Trans } from "react-i18next";
import { InputBaseComponentProps } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckIcon from "@material-ui/icons/Check";
import {
  Form,
  SubscribeLink,
  TabsWrapper,
  Tab,
  InfoText,
  ErrorText,
  CaptchaText,
} from "./components";
import { Consents } from "./Consents";
import {
  H4,
  Button,
  Input,
  isBrowserPaired,
  InputPhoneNumberMask,
  usePairUsername,
} from "../../../common";
import { useSignUpEmail } from "./signUp.api";
import {
  RegistrationFormValues,
  getErrorMsg,
  validateInput,
  Tabs,
  formatMobileNumberInput,
} from "./helpers";
import { ROUTES_PATHS } from "../../../modules";

interface Props {
  isSignIn: boolean;
  alertId?: string;
  onSignInView: () => void;
  onSignUpView: () => void;
}

export const SignUpForm: FC<Props> = ({
  isSignIn,
  alertId,
  onSignInView,
  onSignUpView,
}) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.EMAIL);
  const [validInputValue, setValidInputValue] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    reset,
  } = useForm<RegistrationFormValues>({ defaultValues: { username: "" } });
  const { onSubmit: signUpSubmit, isLoading: signUpLoading } = useSignUpEmail(
    setError,
    alertId,
  );
  const { onSubmit: pairSubmit, isLoading: pairLoading } =
    usePairUsername(alertId);
  const { t } = useTranslation("registration_page");

  const isLoading = signUpLoading || pairLoading;
  const emailInputErrorMsg = getErrorMsg(activeTab, errors);
  const username = watch("username");
  const isActiveEmailTab = activeTab === Tabs.EMAIL;

  const toggleActiveTab = () => {
    setActiveTab((current) => (current === Tabs.EMAIL ? Tabs.SMS : Tabs.EMAIL));
    reset();
  };

  useEffect(() => {
    setValidInputValue(validateInput(activeTab)(username));
  }, [username, activeTab]);

  useEffect(() => {
    if (isBrowserPaired()) {
      navigate(ROUTES_PATHS.REGISTRATION.SUCCESS, { state: { flag: true } });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(isSignIn ? pairSubmit : signUpSubmit)}>
      <H4>{t(isSignIn ? "pair.form.title" : "signup.form.title")}</H4>
      <TabsWrapper activeIndex={activeTab}>
        <Tab
          isActive={isActiveEmailTab}
          onClick={toggleActiveTab}
          disabled={isLoading}
        >
          {t("signup.form.tab.email")}
        </Tab>
        <Tab
          isActive={!isActiveEmailTab}
          onClick={toggleActiveTab}
          disabled={isLoading}
        >
          {t("signup.form.tab.phone")}
        </Tab>
      </TabsWrapper>
      <Controller
        render={({ field }) => (
          <Input
            id="username"
            variant="outlined"
            label={t(
              isActiveEmailTab
                ? "signup.form.email.input.label"
                : "signup.form.phone.input.label",
            )}
            disabled={isLoading}
            {...field}
            onChange={
              isActiveEmailTab
                ? field.onChange
                : (e) => field.onChange(formatMobileNumberInput(e))
            }
            error={!!emailInputErrorMsg}
            helperText={emailInputErrorMsg && t(emailInputErrorMsg)}
            InputProps={{
              endAdornment: validInputValue ? (
                <InputAdornment position="end" data-testid="endAdornment">
                  <CheckIcon />
                </InputAdornment>
              ) : undefined,
              inputComponent: isActiveEmailTab
                ? undefined
                : (InputPhoneNumberMask as ElementType<InputBaseComponentProps>),
            }}
            inputProps={{
              inputMode: isActiveEmailTab ? "email" : "tel",
            }}
          />
        )}
        name="username"
        control={control}
        defaultValue=""
        rules={{
          required: true,
          validate: validateInput(activeTab),
        }}
      />
      {!isActiveEmailTab && !isSignIn && (
        <InfoText>
          <Trans
            t={t}
            i18nKey="consent.sms.info"
            components={{
              b: <b />,
              a: (
                <a
                  href={process.env.REACT_APP_CONTACT_US as string}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                />
              ),
            }}
            values={{
              messagesPerMonth: process.env.REACT_APP_SMS_PER_MONTH,
              helpShortCode: process.env.REACT_APP_HELP_SHORT_CODE,
              stopShortCode: process.env.REACT_APP_STOP_SHORT_CODE,
            }}
          />
        </InfoText>
      )}
      {!isSignIn && (
        <>
          <Consents control={control} tab={activeTab} />
          <ErrorText error={!!errors.consentSms || !!errors.consentEmail}>
            {(!!errors.consentSms || !!errors.consentEmail) &&
              t("consent.error")}
          </ErrorText>
        </>
      )}
      <Button
        variant="contained"
        disableElevation
        type="submit"
        disabled={isLoading}
        $loading={isLoading}
      >
        {t(isSignIn ? "pair.form.button" : "signup.form.button")}
      </Button>
      <SubscribeLink>
        <Trans
          t={t}
          i18nKey={isSignIn ? "pair.form.helper" : "signup.form.helper"}
          components={{
            a: (
              <button
                type="button"
                onClick={isSignIn ? onSignInView : onSignUpView}
              />
            ),
          }}
        />
      </SubscribeLink>
      <CaptchaText>
        <Trans
          t={t}
          i18nKey="signup.form.captcha.policy"
          components={{
            policy: (
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer nofollow"
              />
            ),
            tos: (
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer nofollow"
              />
            ),
          }}
        />
      </CaptchaText>
    </Form>
  );
};
