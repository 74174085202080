import { ElementType, FC, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { formatNumber } from "libphonenumber-js";
import { toWords } from "number-to-words";
import IconButton from "@material-ui/core/IconButton";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { InputBaseComponentProps } from "@material-ui/core/InputBase";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  Wrapper,
  Header,
  Main,
  Section,
  Value,
  ValueWrapper,
  Message,
  Popover,
  ContactText,
  SettingsButtonLabel,
  SettingsButton,
} from "./components";
import {
  H5,
  H6,
  SubTitle2,
  Button,
  Input,
  Loader,
  InputPhoneNumberMask,
} from "../..";
import { isBrowserPaired } from "../..";
import { ROUTES_PATHS, useProfile } from "../../../modules";
import {
  getErrorMsg,
  mapLabels,
  validateInput,
  MessageType,
  MODIFY_INTERVAL_IN_DAYS,
} from "./helpers";
import { ProfileUsernameType } from "../../../modules/App/ProfileProvider/context";
import { Body } from "../Typography";
import { wipeSubscriberData, usePairUsername } from "../..";
import { useUpdateAdditionalContact } from "./updateAdditionalContact.api";
import { useSubscribedAlerts } from "./subscribedAlerts.api";
import { useResubscribe } from "./resubscribe.api";
import { useCheckUpdate } from "./checkUpdate.api";
import { SubscriptionInfo } from "./SubscriptionInfo";

export const Settings: FC = () => {
  const { t } = useTranslation("settings");
  const location = useLocation();
  const { isFetchingProfile, profile, error, canUpdateProfile } = useProfile();
  const navigate = useNavigate();
  const [message, setMessage] = useState<MessageType>(MessageType.NO_MESSAGE);
  const [newUsername, setNewUsername] = useState<string>();
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, errors },
    reset,
    setValue,
  } = useForm<{ additionalContact: string }>({
    defaultValues: {
      additionalContact: profile?.additionalContact ?? "",
    },
  });
  const { onSubmit: handlePair, isLoading: isPairLoading } = usePairUsername();
  const [resubscribe, isResubscribeLoading] = useResubscribe();
  const [subscribedAlerts, isSubscribedAlertsLoading] = useSubscribedAlerts();
  const { handleUpdateAdditionalContact, isUpdateLoading } =
    useUpdateAdditionalContact(setMessage, reset, setNewUsername);
  const [open, setOpen] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  useCheckUpdate(setMessage, setNewUsername);

  const inputErrorMsg = getErrorMsg(errors, profile?.mainContactType);
  const isLoading = isUpdateLoading || isPairLoading || isResubscribeLoading;

  const expiryDurationInMinutes =
    (+`${process.env.REACT_APP_SUBSCRIPTION_TOKEN_EXPIRATION_TIME}` || 0) / 60;

  const isSubscribedMainContact = profile
    ? subscribedAlerts[
        profile.mainContactType === ProfileUsernameType.MOBILE_NUMBER
          ? "sms"
          : "email"
      ]
    : true;

  const isSubscribedAdditionalContact = profile
    ? subscribedAlerts[
        profile.mainContactType === ProfileUsernameType.EMAIL ? "sms" : "email"
      ] || !profile.additionalContact
    : true;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenPopover = () => setOpenPopover(true);
  const handleClosePopover = () => setOpenPopover(false);
  const handleCloseToggle = () => setOpenPopover((s) => !s);

  const handleClickMainContactButton = async () => {
    if (!profile) return;

    if (isSubscribedMainContact) {
      await handlePair({ username: profile.mainContact });
      handleClose();
    } else {
      resubscribe({ mainContact: profile.mainContact });
    }
  };

  const handleClickAdditionalContactButton = async () => {
    if (!profile?.additionalContact) return;

    resubscribe({ additionalContact: profile.additionalContact });
    handleClose();
  };

  const handleReset = () => {
    wipeSubscriberData();
    navigate(ROUTES_PATHS.PAIR.MAIN);
  };

  useEffect(() => {
    if (!profile?.additionalContact) return;
    setValue("additionalContact", profile.additionalContact);
  }, [profile?.additionalContact]);

  useEffect(() => {
    handleClose();
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.startsWith(ROUTES_PATHS.CONFIRM.MAIN)) return;
    if (error) handleOpen();
  }, [error]);

  if (!isBrowserPaired()) {
    return null;
  }

  if (isFetchingProfile || isSubscribedAlertsLoading) {
    return (
      <>
        <SettingsButton onClick={handleOpen}>
          <SettingsOutlinedIcon />
          <SettingsButtonLabel>
            {t("button.label.settings")}
          </SettingsButtonLabel>
        </SettingsButton>
        <Drawer anchor={"right"} open={open} onClose={handleClose}>
          <Wrapper>
            <Header>
              <H5>{t("modal.header")}</H5>
              <IconButton onClick={handleClose}>
                <CloseIcon
                  fontSize={"small"}
                  titleAccess={t("modal.close.button.title")}
                />
              </IconButton>
            </Header>
            <Loader />
          </Wrapper>
        </Drawer>
      </>
    );
  }

  if (error && !profile) {
    return (
      <>
        <SettingsButton onClick={handleOpen}>
          <SettingsOutlinedIcon />
          <SettingsButtonLabel>
            {t("button.label.settings")}
          </SettingsButtonLabel>
        </SettingsButton>
        <Drawer anchor={"right"} open={open} onClose={handleClose}>
          <Wrapper>
            <Header>
              <H5>{t("modal.header")}</H5>
              <IconButton onClick={handleClose}>
                <CloseIcon
                  fontSize={"small"}
                  titleAccess={t("modal.close.button.title")}
                />
              </IconButton>
            </Header>
            <Main>
              <H6>{t("data.corrupted.header")}</H6>
              <Body>{t("data.corrupted.body")}</Body>
            </Main>
            <Button onClick={handleReset}>{t("data.corrupted.button")}</Button>
          </Wrapper>
        </Drawer>
      </>
    );
  }

  // Should never call
  if (!profile) {
    return null;
  }

  // Profile fetched and decrypted successfully
  return (
    <>
      <SettingsButton onClick={handleOpen}>
        <SettingsOutlinedIcon />
        <SettingsButtonLabel>{t("button.label.settings")}</SettingsButtonLabel>
      </SettingsButton>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        data-testid="settings-drawer"
      >
        <Wrapper onSubmit={handleSubmit(handleUpdateAdditionalContact)}>
          <Header>
            <H5>{t("modal.header")}</H5>
            <IconButton
              onClick={handleClose}
              data-testid="settings-drawer-close"
            >
              <CloseIcon
                fontSize={"small"}
                titleAccess={t("modal.close.button.title")}
              />
            </IconButton>
          </Header>
          <Main>
            <Section>
              <SubTitle2>{mapLabels(profile.mainContactType)}</SubTitle2>
              <ValueWrapper>
                <Value>
                  {profile.mainContactType === ProfileUsernameType.MOBILE_NUMBER
                    ? formatNumber(
                        profile.mainContact || "",
                        "US",
                        "International",
                      )
                    : profile.mainContact}
                </Value>
                <div>
                  <Button
                    onClick={handleClickMainContactButton}
                    disabled={isLoading}
                    type="button"
                    variant={isSubscribedMainContact ? "contained" : "outlined"}
                  >
                    {t(
                      isSubscribedMainContact
                        ? "send.pair.link.button"
                        : "send.resubscribe.link.button",
                    )}
                  </Button>
                  <IconButton
                    disableRipple
                    onMouseEnter={handleOpenPopover}
                    onMouseLeave={handleClosePopover}
                    onClick={handleCloseToggle}
                    data-testid="settings-popover"
                  >
                    <InfoOutlinedIcon />
                    <Popover open={openPopover}>
                      <p>{t("popover.info")}</p>
                      <InfoOutlinedIcon />
                    </Popover>
                  </IconButton>
                </div>
              </ValueWrapper>
            </Section>
            <Section>
              <SubTitle2>{mapLabels(profile.mainContactType, true)}</SubTitle2>
              <ValueWrapper withInput>
                <Controller
                  render={({ field }) => (
                    <Input
                      id="additionalContact"
                      variant="outlined"
                      label={
                        profile.mainContactType === ProfileUsernameType.EMAIL
                          ? t("form.label.phone")
                          : t("form.label.email")
                      }
                      disabled={
                        message !== MessageType.NO_MESSAGE ||
                        !isSubscribedAdditionalContact ||
                        isLoading ||
                        !canUpdateProfile
                      }
                      {...field}
                      error={!!inputErrorMsg}
                      helperText={inputErrorMsg && t(inputErrorMsg)}
                      InputProps={{
                        inputComponent:
                          profile.mainContactType ===
                          ProfileUsernameType.MOBILE_NUMBER
                            ? undefined
                            : (InputPhoneNumberMask as ElementType<InputBaseComponentProps>),
                      }}
                    />
                  )}
                  name="additionalContact"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: validateInput(profile.mainContactType),
                  }}
                />
                {!isSubscribedAdditionalContact &&
                  message === MessageType.NO_MESSAGE && (
                    <div>
                      <Button
                        onClick={handleClickAdditionalContactButton}
                        disabled={isLoading}
                        type="button"
                        variant="outlined"
                      >
                        {t("send.resubscribe.link.button")}
                      </Button>
                    </div>
                  )}
              </ValueWrapper>

              {!canUpdateProfile && (
                <Message>
                  {t("form.can.not.update", {
                    modifyInterval: toWords(MODIFY_INTERVAL_IN_DAYS),
                    pluralForm: MODIFY_INTERVAL_IN_DAYS > 1 ? "s" : "",
                  })}
                </Message>
              )}

              <Message>
                {message === MessageType.UPDATE_ADDITIONAL_CONTACT &&
                  canUpdateProfile && (
                    <span>
                      {t("form.success.new.username.prefix")} {newUsername}
                    </span>
                  )}
                {[
                  MessageType.ADD_ADDITIONAL_CONTACT,
                  MessageType.UPDATE_ADDITIONAL_CONTACT,
                ].includes(message) &&
                  canUpdateProfile &&
                  (profile.mainContactType === ProfileUsernameType.EMAIL
                    ? t("form.success.message.phone", {
                        expiryDurationInMinutes: `${expiryDurationInMinutes}`,
                      })
                    : t("form.success.message.email", {
                        expiryDurationInMinutes: `${expiryDurationInMinutes}`,
                      }))}
              </Message>
            </Section>
          </Main>
          <SubscriptionInfo />
          <Button
            type="submit"
            disabled={
              !dirtyFields.additionalContact ||
              isLoading ||
              !isSubscribedAdditionalContact ||
              message !== MessageType.NO_MESSAGE
            }
            $loading={isLoading}
          >
            {t("form.update.button")}
          </Button>
          <ContactText>
            <Trans
              t={t}
              i18nKey="contact.text"
              components={{
                a: (
                  <a
                    href={process.env.REACT_APP_CONTACT_US as string}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  />
                ),
              }}
            />
          </ContactText>
        </Wrapper>
      </Drawer>
    </>
  );
};
