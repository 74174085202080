import { useEffect } from "react";
import {
  DeepMap,
  FieldError,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import {
  GeocodingService,
  GeocodingServiceErrors,
  LoggingService,
  Position,
} from "../../..";
import { useProfile, useMapContext } from "../../../../modules";

export interface ZipFormValues {
  zipCode: string;
}

export const getErrorMsg = (errors: DeepMap<ZipFormValues, FieldError>) => {
  switch (true) {
    case errors.zipCode?.type === "required":
      return "form.error.required";
    case errors.zipCode?.type === "validate":
      return "form.error.validate";
    case errors.zipCode?.type === "pattern":
      return "form.error.pattern";
    case errors.zipCode?.type === "max":
      return "form.error.save";
    default:
      return "";
  }
};

export const useLocationControl = (
  setValue: UseFormSetValue<ZipFormValues>,
  setError: UseFormSetError<ZipFormValues>,
  onSubmit: (data: ZipFormValues) => Promise<void>,
  alertId?: string,
) => {
  const { setPin } = useMapContext();
  const { profile, isFetchingProfile } = useProfile();
  const setPosition = (position: Position | null) =>
    position && setPin(position);

  useEffect(() => {
    if ((isFetchingProfile || !profile) && !alertId) {
      return;
    }

    const zipCode = profile?.location;
    if (zipCode) {
      GeocodingService.getPositionByZIPCode(zipCode).then((position) => {
        if (!position) return;
        setPosition(position);
      });
      return;
    }

    GeocodingService.getLocation()
      .then((position) => {
        if (!position) return;
        GeocodingService.getZIPCodeByPosition(position)
          .then((zip) => {
            if (!zip) {
              return;
            }
            setValue("zipCode", zip);
            setPosition(position);
            onSubmit({ zipCode: zip });
          })
          .catch((e) => {
            if (e?.message === GeocodingServiceErrors.INVALID_ZIP) {
              setError("zipCode", {
                type: "validate",
              });
              return;
            }
            LoggingService.error(e);
          });
      })
      .catch((e) => LoggingService.error(e));
  }, [profile, isFetchingProfile]);
};
