import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";
import RawCloseIcon from "@material-ui/icons/Close";
import { COLORS } from "../../colors";
import { ToastType } from "../../../modules/App/ToastProvider/helper";

interface SnackbarProps {
  $type?: ToastType;
}

export const CloseIcon = styled(RawCloseIcon).attrs({ fontSize: "small" })`
  color: ${COLORS.PRIMARY.LIGHT};
`;

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>`
  & .MuiSnackbarContent-root {
    font-size: 16px;
    font-weight: 700;
    color: ${(props) =>
      props.$type === ToastType.ERROR
        ? COLORS.SNACKBAR.ERROR.FONT
        : COLORS.SNACKBAR.SUCCESS.FONT};
    background-color: ${(props) =>
      props.$type === ToastType.ERROR
        ? COLORS.SNACKBAR.ERROR.BACKGROUND
        : COLORS.SNACKBAR.SUCCESS.BACKGROUND};
  }

  & .MuiSvgIcon-root {
    color: ${(props) =>
      props.$type === ToastType.ERROR
        ? COLORS.SNACKBAR.ERROR.FONT
        : COLORS.SNACKBAR.SUCCESS.FONT};
  }
`;
