import { createContext, useContext } from "react";
import { ToastType } from "./helper";

export interface ToastContext {
  showToast: (msg: string, type?: ToastType) => void;
}

export const ToastContext = createContext<ToastContext>({
  showToast: () => {
    throw new Error("Used outside of context");
  },
});

export const useToast = () => useContext(ToastContext);
