import styled, { css } from "styled-components";
import FormHelperText from "@material-ui/core/FormHelperText";
import { COLORS } from "../../../common/colors";

export const Form = styled.form`
  position: relative;
  flex: 50%;
  margin-bottom: 42px;

  width: 100%;
  height: 100%;

  > button {
    margin: 25px 0 0 0;
  }

  display: flex;
  flex-direction: column;
  background: ${COLORS.SECONDARY.SUPERULTRALIGHT};
  padding: 30px 66px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  color: ${COLORS.FONT.DARK};

  @media only screen and (max-width: 800px) {
    margin-top: 35px;
    padding: 0 20px 25px;

    h4 {
      margin: 20px 0;
    }
  }
`;

export const SubscribeLink = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${COLORS.FONT.DARK};
  margin-top: 30px;

  @media only screen and (max-width: 800px) {
    margin-bottom: 10px;
  }

  button {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding: 0;
    margin: 0;
    background: transparent;
    text-decoration: underline;
    color: inherit;
    border: none;
    cursor: pointer;

    &:hover {
      color: ${COLORS.PRIMARY.HOVER};
      text-decoration: none;
    }
  }
`;

interface TabsWrapperProps {
  activeIndex: number;
}

export const TabsWrapper = styled.div<TabsWrapperProps>`
  width: 100%;
  display: flex;
  padding: 4px;
  background: ${COLORS.BACKGROUND};
  border-radius: 6px;
  margin: 30px 0;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    background: rgba(192, 228, 180, 0.6);
    border-radius: 4px;
    z-index: 1;
    ${(props) =>
      css`
        transform: translateX(${props.activeIndex * 100}%);
      `}
    will-change: transform;
    transition: transform linear 0.2s;
  }
`;

interface TabProps {
  isActive: boolean;
}

export const Tab = styled.button.attrs({ type: "button" })<TabProps>`
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 10px;

  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: left;
  background: transparent;
  will-change: color;
  transition: color linear;
  z-index: 2;
  text-transform: uppercase;
  cursor: pointer;

  ${(props) =>
    props.isActive
      ? css`
          color: ${COLORS.PRIMARY.DARK};
        `
      : css`
          color: ${COLORS.SECONDARY.LIGHT};
        `}
`;

export const ConsentsWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  margin-top: 25px;
`;

export const Consent = styled.li`
  label {
    align-items: flex-start;
  }

  label > span:nth-child(1) {
    margin-top: -7px;
  }

  margin-bottom: 4px;

  :last-child {
    margin-bottom: 0;
  }

  p {
    font-weight: 400;
    line-height: 22px;
    font-size: 14px;
  }

  a {
    color: inherit;
  }

  a:hover {
    text-decoration: none;
  }
`;

export const ErrorText = styled(FormHelperText)`
  color: ${COLORS.ERROR.MAIN}!important;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
`;

export const InfoText = styled(FormHelperText)`
  color: ${COLORS.SECONDARY.MAIN}!important;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: left;
  padding: 0 15px;

  b {
    font-weight: 700;
  }

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const CaptchaText = styled.p`
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${COLORS.SECONDARY.MAIN};
  margin: 0;

  a {
    color: inherit;
  }

  a:hover {
    text-decoration: none;
  }
`;
