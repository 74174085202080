import { useEffect } from "react";
import { useProfile } from "../../../modules";
import { FlexApiService, LoggingService } from "../../services";
import { MessageType } from "./helpers";

export const useCheckUpdate = (
  setMessage: (msg: MessageType) => void,
  setUsername: (username: string) => void,
) => {
  const { profile } = useProfile();
  useEffect(() => {
    if (!profile) return;

    try {
      FlexApiService.get<{
        additionalContact: string;
      }>(`subscriber/profile/${profile.uuid}/contact/active/request`).then(
        (response) => {
          if (response.status !== 200) {
            return;
          }

          if (profile.additionalContact) {
            setMessage(MessageType.UPDATE_ADDITIONAL_CONTACT);
            setUsername(response.data.additionalContact);
          } else {
            setMessage(MessageType.ADD_ADDITIONAL_CONTACT);
            setUsername(response.data.additionalContact);
          }
        },
      );
    } catch (e) {
      LoggingService.error("Email confirm error: ", e);
    }
  }, [profile?.uuid, profile?.additionalContact]);
};
