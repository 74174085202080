import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Location } from "history";

interface LocationState {
  flag?: boolean;
}

export const useAllowOnlyRedirection = (defaultRoute: string) => {
  const location = useLocation() as Location<LocationState>;
  const navigate = useNavigate();

  useEffect(() => {
    // Allow only redirects
    if (!location.state?.flag) {
      navigate(defaultRoute);
    }
  }, [location]);
};
