import { encrypt, decrypt } from "eciesjs";
import { pick } from "lodash";
import {
  FlexApiService,
  LoggingService,
  PrivateExtendedKeyService,
} from "../../../common";
import { Profile } from "./context";

interface FetchEncryptedProfileResponse {
  contactModifyInterval: number;
  encryptedProfile: string;
  contactModifiedDate: number;
}

interface SaveEncryptedProfilePayload {
  encryptedProfile: string;
}

interface CreateEncryptedProfilePayload {
  encryptedProfile: string;
  id: string;
}

interface CreateEncryptedProfileResponse {
  encryptedProfile: string;
}

const PROFILE_PROPERTIES = [
  "location",
  "additionalContact",
  "mainContact",
  "uuid",
] as const;

export const encryptProfile = (profile: Profile) => {
  const publicKey = PrivateExtendedKeyService.getKeyPair?.publicKey;

  if (!publicKey) {
    return null;
  }

  profile = pick(profile, PROFILE_PROPERTIES);

  const encryptedProfile = encrypt(
    publicKey,
    Buffer.from(JSON.stringify(profile)),
  );

  return encryptedProfile.toString("base64");
};

export const decryptProfile = (encryptedProfile: string) => {
  const privateKey = PrivateExtendedKeyService.getKeyPair?.privateKey;

  if (!privateKey) {
    return null;
  }

  const bufferProfile = decrypt(
    privateKey,
    Buffer.from(encryptedProfile, "base64"),
  );

  const rawProfile = JSON.parse(bufferProfile.toString());
  const profile = pick(rawProfile, PROFILE_PROPERTIES);

  return profile as Profile;
};

export const saveEncryptedProfileForLocation = async (
  encryptedProfile: string,
) => {
  const response = await FlexApiService.put<never, SaveEncryptedProfilePayload>(
    `subscriber/profile/location`,
    { encryptedProfile },
  );

  if (response.status !== 200) {
    LoggingService.error(
      "(saveEncryptedProfileForLocation) Different status code: ",
      response.status,
    );
    throw Error();
  }
};

export const createNewProfile = async (
  encryptedProfile: string,
  id: string,
) => {
  const response = await FlexApiService.put<
    CreateEncryptedProfileResponse,
    CreateEncryptedProfilePayload
  >(`subscriber/profile`, { encryptedProfile, id }, true);

  return response;
};

export const fetchProfile = async () => {
  try {
    const response = await FlexApiService.get<FetchEncryptedProfileResponse>(
      `subscriber/profile`,
    );

    switch (response.status) {
      case 200:
        return {
          ...response.data,
          profile: decryptProfile(response.data.encryptedProfile),
        };
      case 404:
        return null;
      default:
        LoggingService.error(
          "(fetchProfile) Different status code:",
          response.status,
        );
        throw new Error();
    }
  } catch (error) {
    LoggingService.error(
      "(fetchProfile) Error while fetching subscriber profile: ",
      error,
    );
    return null;
  }
};
