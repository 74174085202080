import { FC, useEffect } from "react";
import { TileLayer, Marker, useMap } from "react-leaflet";
import { CircleDefs } from "./CircleDefs";
import { MapContainerStyled, Wrapper, CircleStyled } from "./components";
import { getMapboxTileUrl, markerIcon } from "./helpers";
import { Position } from "../../interfaces";
import { useMapContext } from "../../../modules/App/MapProvider";

import "leaflet/dist/leaflet.css";

interface Props {
  blurOnNullPin?: boolean;
  forceBlur?: boolean;
  showPin?: boolean;
  center?: Position;
  data?: {
    zipCode: string;
    responseCount: number;
    longitude: number;
    latitude: number;
    radius: number;
  }[];
}

export const Map: FC<Props> = (props) => {
  const { showPin, blurOnNullPin, data, forceBlur } = props;
  const { pin } = useMapContext();

  return (
    <Wrapper blur={forceBlur || (blurOnNullPin ? !pin : false)}>
      <MapContainerStyled
        center={pin || MAP_DEFAULT_COORDINATES}
        zoom={ZOOM_WITHOUT_PIN}
        maxZoom={ZOOM_WITH_PIN + 1}
        minZoom={ZOOM_WITHOUT_PIN - 1}
        maxBounds={[MAX_SOUTH_WEST, MAX_NORTH_EAST]}
      >
        <TileLayer url={getMapboxTileUrl()} />
        <CircleDefs />
        {data
          ?.filter((bubble) => bubble.longitude && bubble.latitude)
          .map((bubble) => (
            <CircleStyled
              key={bubble.zipCode}
              center={[bubble.latitude, bubble.longitude]}
              radius={bubble.radius}
            />
          ))}
        {showPin && pin && <Marker position={pin} icon={markerIcon} />}
        <MapControl {...props} />
      </MapContainerStyled>
    </Wrapper>
  );
};

const MapControl: FC<Props> = ({ center }) => {
  const map = useMap();
  const { pin } = useMapContext();

  // Update map view when pin changed
  useEffect(() => {
    if (pin) {
      map.setZoom(ZOOM_WITH_PIN);
      map.setView(pin);
    }
  }, pin || ["a", "a"]);

  useEffect(() => {
    center && map.setView(center);
  }, center || ["a", "a"]);

  return null;
};

const MAP_DEFAULT_COORDINATES = [37.0617416, -121.8839659] as Position;
const ZOOM_WITH_PIN = 15;
const ZOOM_WITHOUT_PIN = 6;
const MAX_SOUTH_WEST = [32.5019647016422, -124.514829293866] as Position;
const MAX_NORTH_EAST = [42.0095039998439, -114.131210004164] as Position;
