import styled, { css } from "styled-components";
import { COLORS } from "../../colors";

export const buttonTextStyles = css`
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;
  background: transparent;
  border: none;
  padding: 0;
  color: ${COLORS.PRIMARY.DARK};
  cursor: pointer;
`;

export const ButtonText = styled.button`
  ${buttonTextStyles}
`;
