export const useCaptcha = () => {
  const executeRecaptcha = async (numberOfTries = 0): Promise<string> => {
    if (process.env.REACT_APP_USE_CAPTCHA !== "true") {
      return "";
    }

    return new Promise<string>((resolveToken) => {
      if (!grecaptcha) {
        if (numberOfTries > 4) {
          resolveToken("");
        }

        return new Promise<string>((resolveNewExecution) => {
          setTimeout(
            () => resolveNewExecution(executeRecaptcha(numberOfTries + 1)),
            500,
          );
        });
      }
      grecaptcha.ready(function () {
        grecaptcha
          .execute(process.env.REACT_APP_CAPTCHA_KEY as string, {
            action: "submit",
          })
          .then(function (token) {
            resolveToken(token);
          })
          .catch(function () {
            resolveToken("");
          });
      });
    });
  };

  return { executeRecaptcha };
};
