import { createContext, useContext } from "react";

export interface Profile {
  uuid: string;
  mainContact: string;
  additionalContact?: string;
  location?: string;
}

export interface ProfileWithUsernameType {
  uuid: string;
  mainContact: string;
  mainContactType: ProfileUsernameType;
  additionalContact?: string;
  location?: string;
  contactModifyInterval: number;
}

export enum ProfileUsernameType {
  EMAIL = "EMAIL",
  MOBILE_NUMBER = "MOBILE_NUMBER",
}

export enum ProfileError {
  NOT_FOUND = "NOT_FOUND",
  DATA_CORRUPTED = "DATA_CORRUPTED",
  UNKNOWN = "UNKNOWN",
}

export interface ProfileContext {
  isFetchingProfile: boolean;
  profile: ProfileWithUsernameType | null;
  error: ProfileError | null;
  canUpdateProfile: boolean;
  createProfile: (
    profile: Profile,
    id: string,
  ) => Promise<ProfileWithUsernameType | false>;
  setLocation: (zipCode: string) => Promise<void>;
  getNewEncryptedProfileWithAdditionalContact: (
    additionalContact: string,
    profileRef?: ProfileWithUsernameType,
  ) => string | null;
  updateAdditionalContact: (additionalContact: string) => void;
  refetchProfile: () => Promise<ProfileWithUsernameType | undefined>;
}

export const ProfileContext = createContext<ProfileContext>({
  isFetchingProfile: false,
  profile: null,
  error: null,
  canUpdateProfile: true,
  createProfile: () => {
    throw new Error("Used outside of context");
  },
  setLocation: () => {
    throw new Error("Used outside of context");
  },
  getNewEncryptedProfileWithAdditionalContact: () => {
    throw new Error("Used outside of context");
  },
  updateAdditionalContact: () => {
    throw new Error("Used outside of context");
  },
  refetchProfile: () => {
    throw new Error("Used outside of context");
  },
});

export const useProfile = () => useContext(ProfileContext);
