import { FC, useEffect, useRef } from "react";
import lottie, { AnimationItem } from "lottie-web";
import { Wrapper } from "./components";
import animationData from "./animation.json";
import { Caption } from "../Typography";

export const Loader: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animation: AnimationItem;

    if (containerRef.current) {
      animation = lottie.loadAnimation({
        animationData,
        container: containerRef.current,
        loop: true,
        autoplay: true,
      });
    }

    return () => {
      animation && animation.destroy();
    };
  }, [animationData]);

  return (
    <Wrapper data-testid="loader" ref={containerRef}>
      <Caption>Loading...</Caption>
    </Wrapper>
  );
};
