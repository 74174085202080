import styled, { css } from "styled-components";
import RawButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { COLORS } from "../../colors";
import { FC } from "react";

interface ButtonProps {
  $loading?: boolean;
}

export const buttonStyles = css`
  background-color: ${COLORS.PRIMARY.MAIN};
  color: ${COLORS.FONT.LIGHT};
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 400;
  border-radius: 6px;
  padding: 16px 25px;

  :hover {
    background-color: ${COLORS.PRIMARY.HOVER};
  }

  :disabled {
    background-color: ${COLORS.SURFACE};
    color: ${COLORS.SECONDARY.LIGHT};
  }

  &.MuiButton-outlined {
    background-color: transparent;
    color: ${COLORS.SECONDARY.MAIN};
    border: 1px solid ${COLORS.SECONDARY.MAIN};
  }

  &.MuiButton-outlined:hover {
    background-color: ${COLORS.SECONDARY.MAIN};
    color: ${COLORS.FONT.LIGHT};
  }
`;

export const Button = styled(RawButton).attrs<ButtonProps>((props) => ({
  children: props.$loading ? (
    <ButtonLoadingWrapper>{props.children}</ButtonLoadingWrapper>
  ) : (
    props.children
  ),
}))<ButtonProps>`
  ${buttonStyles}

  ${(props) =>
    props.$loading &&
    css`
      cursor: default;
      background-color: ${COLORS.PRIMARY.DARK};

      :disabled {
        background-color: ${COLORS.PRIMARY.DARK};
        color: ${COLORS.FONT.LIGHT};
      }
    `}
`;

const CircularProgressStyled = styled(CircularProgress).attrs({ size: 24 })`
  color: ${COLORS.FONT.LIGHT};
  position: absolute;
`;

const ButtonLoadingContainer = styled.span`
  opacity: 0;
`;

const ButtonLoadingWrapper: FC = ({ children }) => {
  return (
    <>
      <ButtonLoadingContainer>{children}</ButtonLoadingContainer>
      <CircularProgressStyled />
    </>
  );
};
