import HDKey from "hdkey";

class PrivateExtendedKeyClass {
  KEY = "privateExtendedKey";
  hdKeyPair: HDKey | null = null;

  constructor() {
    this.initHdKeyPair();
  }

  private initHdKeyPair() {
    if (!this.has()) {
      return;
    }
    this.hdKeyPair = HDKey.fromExtendedKey(this.get() || "");
  }

  has() {
    return this.get() !== null;
  }

  get() {
    const privateKey = localStorage.getItem(this.KEY);

    return privateKey;
  }

  set(value: string) {
    if (!value) {
      return;
    }
    localStorage.setItem(this.KEY, value);
    this.initHdKeyPair();
    return;
  }

  clear() {
    localStorage.removeItem(this.KEY);
  }

  get getKeyPair() {
    if (!this.hdKeyPair) {
      return;
    }

    const privateKey = `0x${this.hdKeyPair.privateKey.toString("hex")}`;
    const publicKey = `0x${this.hdKeyPair.publicKey.toString("hex")}`;

    return { privateKey, publicKey };
  }
}

export const PrivateExtendedKeyService = new PrivateExtendedKeyClass();
// Usage: PrivateExtendedKeyService.set("tg476656758bkeufgid6957")
