import styled, { css } from "styled-components";

export const H1 = styled.h1`
  font-family: "Fira Sans", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: 112px;
  letter-spacing: -1.5px;
  text-align: left;

  margin: 0;
`;

export const H2 = styled.h2`
  font-family: "Fira Sans", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 71px;
  letter-spacing: -0.5px;
  text-align: left;

  margin: 0;
`;

export const H3 = styled.h3`
  font-family: "Fira Sans", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 57px;
  text-align: left;

  margin: 0;
`;

export const H4 = styled.h4`
  font-family: "Fira Sans", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.25px;
  text-align: left;

  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 24px;
  }
`;

export const H5 = styled.h5`
  font-family: "Fira Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;

  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const H6 = styled.h6`
  font-family: "Fira Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;

  margin: 0;
`;

export const SubHeading = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.44px;
  text-align: left;

  margin: 0;

  @media only screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SubTitle1 = styled.h6`
  font-family: "Fira Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-align: left;

  margin: 0;
`;

export const SubTitle2 = styled.h6`
  font-family: "Fira Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;

  margin: 0;
`;

export const Body = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.44px;
  text-align: left;

  margin: 0;
`;

export const Body2 = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;

  margin: 0;
`;

export const captionStyle = css`
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;

  margin: 0;
`;

export const Caption = styled.p`
  ${captionStyle}
`;
