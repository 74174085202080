import { useState } from "react";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ZipCodeInput, SubHeading, H6, Button } from "../..";
import {
  GeocodingService,
  isValidZIPCode,
  REGEX_ZIP,
  ZipCodeDoesNotExist,
} from "../../..";
import { useToast, useProfile, useMapContext } from "../../../../modules";
import { ToastType } from "../../../../modules/App/ToastProvider";
import { ResponseService } from "../../../services";
import { isBrowserPaired } from "../../../utils";
import { ZipForm, ZipFormWrapper } from "./components";
import { ZipFormValues, getErrorMsg, useLocationControl } from "./helpers";
import { useUpdateResponseLocation } from "./updateResponseLocation.api";

interface Props {
  alertId?: string;
  responseLocation?: string;
  blockLocationUpdate?: boolean;
  onChange?: (zipCose: string) => void;
}

export const ZipCodeForm: FC<Props> = ({
  alertId,
  responseLocation,
  blockLocationUpdate,
  onChange,
}) => {
  const { t } = useTranslation("zip_form");
  const { showToast } = useToast();
  const { setPin, clearPin } = useMapContext();
  const { setLocation, profile, error } = useProfile();
  const [isLoading, setIsLoading] = useState(false);
  const zipCode = profile?.location;
  const hasZipCode = !!profile?.location;
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm<ZipFormValues>({
    defaultValues: { zipCode: responseLocation ?? zipCode ?? "" },
  });
  const [updateResponseLocation, { isDisabled }] =
    useUpdateResponseLocation(setError);

  const onSubmit = async (data: ZipFormValues) => {
    const { zipCode: newZipCode } = data;

    try {
      if (blockLocationUpdate) {
        return;
      }

      setIsLoading(true);

      if (alertId) {
        await updateResponseLocation(data);

        if (!profile || error) {
          return;
        }
      }

      await setLocation(newZipCode);

      !alertId && showToast(t("toast.zip.code.save"), ToastType.SUCCESS);
    } catch (e) {
      if (e instanceof ZipCodeDoesNotExist) {
        setError("zipCode", {
          type: "validate",
        });
        return;
      }

      showToast(t("toast.zip.code.save.error"), ToastType.ERROR);
      setError("zipCode", {
        type: "max",
      });
    } finally {
      GeocodingService.getPositionByZIPCode(newZipCode).then((position) => {
        if (!position) {
          clearPin();
          return;
        }
        setPin(position);
      });
      onChange && onChange(data.zipCode);
      setIsLoading(false);
    }
  };

  useLocationControl(setValue, setError, onSubmit, alertId);

  useEffect(() => {
    const zipCode = profile?.location;

    if (zipCode && zipCode !== getValues().zipCode) {
      setValue("zipCode", zipCode);
    }
  }, [profile]);

  useEffect(() => {
    const zipCode = ResponseService.get()?.location;
    if (!zipCode) return;
    GeocodingService.getPositionByZIPCode(zipCode).then((position) => {
      if (!position) {
        clearPin();
        return;
      }
      setPin(position);
    });
  }, [alertId]);

  useEffect(() => {
    const zipCode = responseLocation;

    if (zipCode && zipCode !== getValues().zipCode) {
      setValue("zipCode", zipCode);
    }
  }, [responseLocation]);

  const errorMsg = getErrorMsg(errors);
  const formIsDisabled =
    isDisabled || isLoading || (isBrowserPaired() && !profile);

  if (error && !alertId) {
    return null;
  }

  return (
    <ZipFormWrapper>
      {hasZipCode ? (
        <>
          <H6>{t("form.zip.heading")}</H6>
          <SubHeading>{t("form.zip.subheading")}</SubHeading>
        </>
      ) : (
        <>
          <SubHeading>{t("form.no.zip.subheading")}</SubHeading>
        </>
      )}

      <ZipForm onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <ZipCodeInput
              defaultValue={value}
              onChange={onChange}
              error={!!errorMsg}
              helperText={errorMsg && t(errorMsg)}
              disabled={formIsDisabled}
            />
          )}
          name="zipCode"
          control={control}
          rules={{
            required: true,
            pattern: REGEX_ZIP,
            validate: isValidZIPCode,
          }}
        />

        <Button
          variant="contained"
          disableElevation
          type="submit"
          disabled={formIsDisabled}
        >
          {t("form.button")}
        </Button>
      </ZipForm>
    </ZipFormWrapper>
  );
};
