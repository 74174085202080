export enum ToastType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface ToastItem {
  id: string;
  message: string;
  type?: ToastType;
}

export const generateToastId = () => Math.random().toString(36).substring(7);
