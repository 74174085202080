import { useState } from "react";
import { UseFormSetError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";
import { ROUTES_PATHS } from "../../../modules";
import {
  FlexApiService,
  LoggingService,
  isValidUSPhoneNumber,
  useParamsQuery,
  useCaptcha,
} from "../../../common";
import { RegistrationFormValues } from "./helpers";
import { ResponseService } from "../../services";

export const useSignUpEmail = (
  setError: UseFormSetError<RegistrationFormValues>,
  alertId?: string,
) => {
  const navigate = useNavigate();
  const paramsQuery = useParamsQuery();
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useCaptcha();

  const getSourceFromURL = () => {
    return paramsQuery.get("source") || undefined;
  };

  const onSubmit = async (data: RegistrationFormValues) => {
    const isPhoneNumberRegistration = isValidUSPhoneNumber(data.username);

    if (!executeRecaptcha) {
      LoggingService.warning("reCAPTCHA is not available");
      return;
    }

    try {
      setIsLoading(true);

      const parsedData = { username: data.username };
      const responseData: { alertId?: string; responseId?: string } = {};

      if (isPhoneNumberRegistration) {
        parsedData.username = parsePhoneNumber(data.username).format("E.164");
      }

      if (alertId) {
        responseData.alertId = alertId;
        responseData.responseId = ResponseService.get()?.responseId;
      }

      const captchaToken = await executeRecaptcha();
      const response = await FlexApiService.post<
        SignUpAPIResponse,
        SignUpAPIPayload
      >("subscriber", {
        ...parsedData,
        ...responseData,
        captcha: captchaToken === "" ? undefined : captchaToken,
        source: getSourceFromURL(),
      });
      setIsLoading(false);

      switch (response.status) {
        // 400 - bad request (form error)
        case 400:
          setError("username", {
            type: "pattern",
            shouldFocus: true,
          });
          break;

        // 201 - sent (success)
        // 409 - user have active token assigned
        case 409:
        case 201:
          navigate(ROUTES_PATHS.REGISTRATION.SENT, {
            state: { isPhoneNumberRegistration, flag: true },
          });
          break;

        default:
          navigate(ROUTES_PATHS.ERROR.INTERNAL);
          break;
      }
    } catch (error) {
      LoggingService.error("Sign up error: ", error);
      navigate(ROUTES_PATHS.ERROR.INTERNAL);
    }
  };

  return { onSubmit, isLoading };
};

type SignUpAPIPayload = Pick<RegistrationFormValues, "username"> & {
  captcha?: string;
  source?: string;
  responseId?: string;
  alertId?: string;
};

type SignUpAPIResponse =
  | string
  | {
      statusCode: number;
      message: string | string[];
      error: string;
    };
