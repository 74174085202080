import { useState } from "react";
import { LoggingService, queryZipCode as fetchZipCode } from "../../..";

export const useQueryZipCode = (
  setOptions?: (x: ZipCodeAPIResponse) => void,
) => {
  const [loading, setLoading] = useState(false);
  const queryZipCode = async (query: string) => {
    try {
      setLoading(true);

      const data = await fetchZipCode(query);

      if (!data) {
        return;
      }

      if (setOptions) {
        setOptions(data);
      }

      return data;
    } catch (error) {
      LoggingService.error("Error while query zip code: ", error);
    } finally {
      setLoading(false);
    }
  };

  return { queryZipCode, loading };
};

export type ZipCodeAPIResponse = { zipCode: string; city: string }[];
