import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Wrapper, TopBox, BottomBox, Text, TextWrapper } from "./components";

export const FillerImage: FC = () => {
  const { t } = useTranslation("layout");
  return (
    <Wrapper>
      <TextWrapper>
        <Text
          href="http://www.flexalert.org/save-energy"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <Trans
            i18nKey="filler.image.text"
            components={{
              span: <span />,
              br: <br />,
            }}
            t={t}
          />
          <ArrowForwardIcon />
        </Text>
      </TextWrapper>
      <BottomBox />
      <TopBox />
    </Wrapper>
  );
};
