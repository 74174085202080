import { startOfDay, format, isToday, isTomorrow } from "date-fns";
import { id, joinSignature, SigningKey } from "ethers/lib/utils";
import { PrivateExtendedKeyService } from "../../../common";

export interface ViewProps {
  flexAlertType: FlexAlertType;
  flexAlertDuration: FlexAlertDuration;
}

export enum FlexAlertType {
  EXPIRED = "EXPIRED",
  DURING = "DURING",
  UPCOMING = "UPCOMING",
}

export type FlexAlertDuration = {
  from: Date;
  to: Date;
  schedules: {
    validFrom: Date;
    validTo: Date;
  }[];
} | null;

export const signResponse = (
  alertId: string,
  sourceCode?: string,
  zipCode?: string,
) => {
  const utf8BytesData = id(JSON.stringify({ alertId, sourceCode, zipCode }));
  const signingKey = new SigningKey(
    PrivateExtendedKeyService.getKeyPair?.privateKey as string,
  );
  const signature = joinSignature(signingKey.signDigest(utf8BytesData));
  return signature;
};

export const getFlexAlertType = (date: { from: Date; to: Date }) => {
  const now = Date.now();
  const from = date.from.getTime();
  const to = date.to.getTime();
  if (to < now) {
    return FlexAlertType.EXPIRED;
  }

  if (from > now) {
    return FlexAlertType.UPCOMING;
  }

  return FlexAlertType.DURING;
};

export const getTranslations = (
  alertType: FlexAlertType,
  isMulti: boolean,
  county?: string,
  numberOfParticipants?: number,
) => {
  switch (alertType) {
    case FlexAlertType.EXPIRED:
      if (!county) {
        return {
          heading: "success.expired.heading",
          subheading: isMulti
            ? "success.expired.no.zip.code.subheading.multi"
            : "success.expired.no.zip.code.subheading",
        } as const;
      }
      return {
        heading: "success.expired.heading",
        subheading: isMulti
          ? numberOfParticipants !== 0
            ? "success.expired.subheading.multi.plural"
            : "success.expired.subheading.multi.singular"
          : "success.expired.subheading",
      } as const;
    case FlexAlertType.DURING:
      if (!county) {
        return {
          heading: "success.during.heading",
          subheading: isMulti
            ? "success.no.zip.code.subheading.multi"
            : "success.no.zip.code.subheading",
        } as const;
      }
      return {
        heading: "success.during.heading",
        subheading:
          (numberOfParticipants || 1) < 2
            ? isMulti
              ? "success.during.subheading.one.response.multi"
              : "success.during.subheading.one.response"
            : isMulti
            ? "success.during.subheading.multi"
            : "success.during.subheading",
      } as const;
    case FlexAlertType.UPCOMING:
      if (!county) {
        return {
          heading: "success.upcoming.heading",
          subheading: isMulti
            ? "success.no.zip.code.subheading.multi"
            : "success.no.zip.code.subheading",
        } as const;
      }
      return {
        heading: "success.upcoming.heading",
        subheading:
          (numberOfParticipants || 1) < 2
            ? isMulti
              ? "success.upcoming.subheading.one.response.multi"
              : "success.upcoming.subheading.one.response"
            : isMulti
            ? "success.upcoming.subheading.multi"
            : "success.upcoming.subheading",
      } as const;
  }
};

export const getDateTimeTranslations = (
  alertType: FlexAlertType,
  alertDuration: FlexAlertDuration,
  isAnonymous?: boolean,
) => {
  if (!alertDuration) return null;

  const fromTimeStart = alertDuration.from;
  const fromTimeEnd =
    alertDuration.schedules.find(
      (schedule) =>
        schedule.validFrom.getTime() === alertDuration.from.getTime(),
    )?.validTo || new Date();

  const toTimeEnd = alertDuration.to;
  const toTimeStart =
    alertDuration.schedules.find(
      (schedule) => schedule.validTo.getTime() === alertDuration.to.getTime(),
    )?.validFrom || new Date();
  const startOfToday = startOfDay(Date.now()).getTime();
  const todayFATime = alertDuration.schedules.find(
    (schedule) => startOfDay(schedule.validFrom).getTime() === startOfToday,
  );

  const isSingleDay = alertDuration.schedules.length === 1;
  const isEventToday = isToday(alertDuration.from);
  const isEventTomorrow = isTomorrow(alertDuration.from);
  const isDifferentTimeRange =
    fromTimeStart.getHours() !== toTimeStart.getHours() ||
    fromTimeStart.getMinutes() !== toTimeStart.getMinutes() ||
    fromTimeEnd.getHours() !== toTimeEnd.getHours() ||
    fromTimeEnd.getMinutes() !== toTimeEnd.getMinutes();

  if (isSingleDay && isEventTomorrow) {
    return {
      key: isAnonymous
        ? "success.anonymous.date.time.single.tomorrow"
        : "success.date.time.single.tomorrow",
      vars: {
        dateStart: format(fromTimeStart, DATE_FORMAT),
        timeStart: formaTime(fromTimeStart),
        timeEnd: formaTime(toTimeEnd),
      },
    } as const;
  }

  if (isSingleDay && !isEventToday && alertType === FlexAlertType.UPCOMING) {
    return {
      key: isAnonymous
        ? "success.anonymous.date.time.single.upcoming"
        : "success.date.time.single.upcoming",
      vars: {
        timeStart: formaTime(fromTimeStart),
        timeEnd: formaTime(toTimeEnd),
        dateStart: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
      },
    } as const;
  }

  if (isSingleDay && isEventToday) {
    return {
      key: isAnonymous
        ? "success.anonymous.date.time.single.today"
        : "success.date.time.single.today",
      vars: {
        dateStart: format(fromTimeStart, DATE_FORMAT),
        timeStart: formaTime(
          todayFATime ? todayFATime.validFrom : fromTimeStart,
        ),
        timeEnd: formaTime(todayFATime ? todayFATime.validTo : toTimeEnd),
      },
    } as const;
  }

  if (!isSingleDay && isEventTomorrow && !isDifferentTimeRange) {
    return {
      key: "success.date.time.multi.tomorrow",
      vars: {
        timeStart: formaTime(fromTimeStart),
        timeEnd: formaTime(toTimeEnd),
        dateEnd: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
      },
    } as const;
  }

  if (!isSingleDay && isEventTomorrow && isDifferentTimeRange) {
    return {
      key: "success.date.time.multi.tomorrow.different",
      vars: {
        fromTimeStart: formaTime(fromTimeStart),
        fromTimeEnd: formaTime(toTimeEnd),
        dateEnd: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
        endTimeStart: formaTime(toTimeStart),
        endTimeEnd: formaTime(toTimeEnd),
      },
    } as const;
  }

  if (!isSingleDay && isEventToday && !isDifferentTimeRange) {
    return {
      key: "success.date.time.multi.today",
      vars: {
        timeStart: formaTime(
          todayFATime ? todayFATime.validFrom : fromTimeStart,
        ),
        timeEnd: formaTime(todayFATime ? todayFATime.validTo : toTimeEnd),
        dateEnd: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
      },
    } as const;
  }

  if (!isSingleDay && isEventToday && isDifferentTimeRange) {
    return {
      key: "success.date.time.multi.today.different",
      vars: {
        fromTimeStart: formaTime(
          todayFATime ? todayFATime.validFrom : fromTimeStart,
        ),
        fromTimeEnd: formaTime(todayFATime ? todayFATime.validTo : toTimeEnd),
        dateEnd: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
        endTimeStart: formaTime(toTimeStart),
        endTimeEnd: formaTime(toTimeEnd),
      },
    } as const;
  }

  if (
    !isSingleDay &&
    alertType === FlexAlertType.UPCOMING &&
    !isDifferentTimeRange
  ) {
    return {
      key: "success.date.time.multi.upcoming",
      vars: {
        timeStart: formaTime(fromTimeStart),
        timeEnd: formaTime(toTimeEnd),
        dateStart: format(fromTimeStart, DATE_FORMAT_WITH_DAY_OF_WEEK),
        dateEnd: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
      },
    } as const;
  }

  if (
    !isSingleDay &&
    alertType === FlexAlertType.UPCOMING &&
    isDifferentTimeRange
  ) {
    return {
      key: "success.date.time.multi.upcoming.different",
      vars: {
        fromTimeStart: formaTime(fromTimeStart),
        fromTimeEnd: formaTime(fromTimeEnd),
        dateStart: format(fromTimeStart, DATE_FORMAT_WITH_DAY_OF_WEEK),
        dateEnd: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
        endTimeStart: formaTime(toTimeStart),
        endTimeEnd: formaTime(toTimeEnd),
      },
    } as const;
  }

  if (!isSingleDay) {
    // Expired
    return {
      key: isAnonymous
        ? "success.anonymous.date.time.expired.multi"
        : "success.date.time.expired.multi",
      vars: {
        fromTimeStart: formaTime(fromTimeStart),
        fromTimeEnd: formaTime(fromTimeEnd),
        dateStart: format(fromTimeStart, DATE_FORMAT_WITH_DAY_OF_WEEK),
        dateEnd: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
        endTimeStart: formaTime(toTimeStart),
        endTimeEnd: formaTime(toTimeEnd),
      },
    } as const;
  }

  // Expired
  return {
    key: "success.date.time.expired",
    vars: {
      timeStart: formaTime(fromTimeStart),
      timeEnd: formaTime(toTimeEnd),
      dateStart: format(toTimeEnd, DATE_FORMAT_WITH_DAY_OF_WEEK),
    },
  } as const;
};

export const getAnonymousTranslations = (
  alertType: FlexAlertType,
  isMulti: boolean,
) => {
  switch (alertType) {
    case FlexAlertType.EXPIRED:
      return {
        heading: "success.expired.heading",
        subheading: isMulti
          ? "success.anonymous.expired.subheading.multi"
          : "success.anonymous.expired.subheading",
      } as const;
    case FlexAlertType.DURING:
      return {
        heading: "success.during.heading",
        subheading: isMulti
          ? "success.anonymous.during.subheading.multi"
          : "success.anonymous.during.subheading",
      } as const;
    case FlexAlertType.UPCOMING:
      return {
        heading: "success.upcoming.heading",
        subheading: isMulti
          ? "success.anonymous.during.subheading.multi"
          : "success.anonymous.during.subheading",
      } as const;
  }
};

export const formaTime = (value: Date) => {
  if (value.getMinutes() === 0) {
    return format(value, "h aaaa");
  }
  return format(value, "h:mm aaaa");
};

export const DATE_FORMAT = "MMMM d";
export const DATE_FORMAT_WITH_DAY_OF_WEEK = "cccc, MMMM d";

export interface MultipleDateTimeTranslations {
  from: string;
  to: string;
  today: string;
  tomorrow: string;
}

export const formatDates = (
  validFrom: Date,
  validTo: Date,
  translations: MultipleDateTimeTranslations,
) => {
  const isEventToday = isToday(validFrom);
  const isEventTomorrow = isTomorrow(validFrom);

  const minuteDisplayFormat = (date: Date) => {
    const min = format(date, "mm");
    const minutePart = min === "00" ? "" : ":" + min;
    return `${minutePart} ${format(date, "aaaa")}`;
  };

  const validFromMinutes = minuteDisplayFormat(validFrom);
  const validToMinutes = minuteDisplayFormat(validTo);

  const displayTimePostfix = `${translations.from} ${format(
    validFrom,
    "h",
  )}${validFromMinutes} ${translations.to} ${format(
    validTo,
    "h",
  )}${validToMinutes}`;

  if (isEventToday) {
    return `${translations.today}, ${format(
      validFrom,
      "MMMM d",
    )}, ${displayTimePostfix}`;
  }

  if (isEventTomorrow) {
    return `${translations.tomorrow}, ${format(
      validFrom,
      "MMMM d",
    )}, ${displayTimePostfix}`;
  }

  return `${format(validFrom, "EEEE, MMMM d")}, ${displayTimePostfix}`;
};
