import styled, { css } from "styled-components";
import MyLocationRawIcon from "@material-ui/icons/MyLocation";
import { Input } from "../Input";
import { COLORS } from "../../../";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  & :first-of-type {
    font-family: "Fira Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
    color: ${COLORS.FONT.DARK};
  }

  & :last-of-type {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    color: ${COLORS.SECONDARY.LIGHT};
  }
`;

export const CityValue = styled.span`
  position: absolute;
  bottom: 20.5px;
  left: 46px;

  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.25px;
  text-align: left;
  color: ${COLORS.SECONDARY.MAIN};

  @media (max-width: 380px) {
    bottom: 7.5px;
  }
`;

export const StyledInput = styled(Input)`
  height: 60px;

  & label.Mui-focused,
  & .MuiFormLabel-filled {
    transform: translate(46px, 6px) scale(1) !important;
  }

  & .MuiInputBase-input {
    transform: translateY(-5px);
  }

  & .MuiInputBase-root {
    height: 100%;
  }
`;

interface MyLocationIconProps {
  $featured?: boolean;
}
export const MyLocationIcon = styled(MyLocationRawIcon)<MyLocationIconProps>`
  ${(props) =>
    props.$featured &&
    css`
      color: ${COLORS.PRIMARY.MAIN};
    `}
`;
