import { FormEvent } from "react";
import { DeepMap, FieldError } from "react-hook-form";
import { REGEX_EMAIL, isValidUSPhoneNumber } from "../../../common";

export interface RegistrationFormValues {
  username: string;
  consentSms: boolean[];
  consentEmail: boolean[];
}

export enum Tabs {
  EMAIL = 0,
  SMS = 1,
}

export const getErrorMsg = (
  activeTab: Tabs,
  errors: DeepMap<RegistrationFormValues, FieldError>,
) => {
  switch (true) {
    case errors.username?.type === "required" && activeTab === Tabs.EMAIL:
      return "signup.email.error.required";
    case errors.username?.type === "validate" && activeTab === Tabs.EMAIL:
    case errors.username?.type === "pattern" && activeTab === Tabs.EMAIL:
      return "signup.email.error.pattern";
    case errors.username?.type === "required" && activeTab === Tabs.SMS:
      return "signup.phone.error.required";
    case errors.username?.type === "validate" && activeTab === Tabs.SMS:
    case errors.username?.type === "pattern" && activeTab === Tabs.SMS:
      return "signup.phone.error.pattern";
    default:
      return "";
  }
};

export const validateInput = (activeTab?: Tabs) => (value?: string) => {
  if (!value) return false;

  switch (activeTab) {
    case Tabs.EMAIL:
      return !!value.match(REGEX_EMAIL);
    case Tabs.SMS:
      return isValidUSPhoneNumber(value);
  }

  return false;
};

export const formatMobileNumberInput = (
  event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const value = event.currentTarget.value;
  // Accept only numbers
  const newValue = value.replace(/[a-z]/gi, "");
  event.currentTarget.value = newValue;
  return event;
};
