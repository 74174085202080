import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { init as SentryInit } from "@sentry/react";
import { App, SentryProvider } from "./modules/App";
import "./i18n";

if (process.env.REACT_APP_USE_SENTRY === "true") {
  SentryInit({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
  <React.StrictMode>
    <SentryProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SentryProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
