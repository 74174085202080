export const COLORS = {
  BACKGROUND: "#FFFFFF",
  SURFACE: "#CFD8DC",
  PRIMARY: {
    MAIN: "#6BBD45",
    LIGHT: "#C3E4B4",
    DARK: "#4A8430",
    HOVER: "#427539",
  },
  SECONDARY: {
    MAIN: "#115574",
    LIGHT: "#819CA9",
    ULTRALIGHT: "#ECEFF1",
    SUPERULTRALIGHT: "#E1E7EB",
    DARK: "#29434E",
    ORANGE: "#D15B26",
  },
  INPUT: {
    BACKGROUND: "#FAFAFA",
    BORDER: "#CFD8DC",
  },
  FONT: {
    LIGHT: "#FFFFFF",
    DARK: "#212121",
  },
  ERROR: {
    MAIN: "#DB0028",
  },
  SNACKBAR: {
    SUCCESS: {
      FONT: "#4A8430",
      BACKGROUND: "#C3E4B4",
    },
    ERROR: {
      FONT: "#DB0028",
      BACKGROUND: "#FCE8E7",
    },
  },
} as const;
