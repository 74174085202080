import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useMatch } from "react-router";
import Container from "@material-ui/core/Container";
// import IconButton from "@material-ui/core/IconButton";
// import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ROUTES_PATHS } from "../..";
import {
  Wrapper,
  Footer,
  FooterFeatured,
  Copyright,
  Menu,
  StyledLink,
  CopyrightWrapper,
  Header,
  LogoWrappers,
  Main,
} from "./components";
import {
  CaisoLogoFooter,
  GreenLogoFooter,
  CaisoLogoHeader,
  GreenLogoHeader,
  CaisoLogoSingleFooter,
  Identity,
  Settings,
} from "../../../common/components";
import { footerLinks } from "./footerLinks";

interface Props {
  children: ReactNode;
}

export const Layout: FC<Props> = ({ children }) => {
  const [gradientBg, setGradientBg] = useState(false);
  const { pathname } = useLocation();
  const match = useMatch(pathname);
  const { t } = useTranslation("layout");

  useEffect(() => {
    if (!match) return;

    setGradientBg(
      (
        [
          ROUTES_PATHS.ERROR.INTERNAL,
          ROUTES_PATHS.ERROR.NOT_FOUND,
          ROUTES_PATHS.ERROR.UNAUTHORIZED,
          ROUTES_PATHS.EVENT.INVALID,
        ] as string[]
      ).includes(match.pathname),
    );
  }, [match?.pathname]);

  return (
    <Wrapper gradientBg={gradientBg}>
      <Container>
        <Header>
          <LogoWrappers>
            <GreenLogoHeader alt={t("image.alt.fa.logo")} />
            <CaisoLogoHeader alt={t("image.alt.caiso.logo")} />
          </LogoWrappers>
          <Settings />
        </Header>
      </Container>
      <Main>{children}</Main>
      <Footer>
        <Container>
          <FooterFeatured>
            <LogoWrappers>
              <GreenLogoFooter alt={t("image.alt.fa.logo")} />
              <CaisoLogoFooter alt={t("image.alt.caiso.logo")} />
              <CaisoLogoSingleFooter alt={t("image.alt.caiso.logo")} />
            </LogoWrappers>
            <Menu>
              <Identity />
              {footerLinks.map((item) => (
                <StyledLink
                  href={item.href}
                  target="_blank"
                  key={item.title}
                  rel="noopener noreferrer nofollow"
                >
                  {t(item.title)}
                </StyledLink>
              ))}
              {/* <IconButton>
                <HelpOutlineIcon />
              </IconButton> */}
            </Menu>
          </FooterFeatured>
        </Container>
        <CopyrightWrapper>
          <Container>
            <Copyright>{t("footer.copyright")}</Copyright>
            <Copyright>{t("footer.powered.by.ew")}</Copyright>
          </Container>
        </CopyrightWrapper>
      </Footer>
    </Wrapper>
  );
};
