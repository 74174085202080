import styled, { css } from "styled-components";
import { MapContainer, Circle } from "react-leaflet";

interface WrapperProps {
  blur?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  max-width: 642px;
  width: 100%;
  height: 100%;

  border-radius: 25px;
  overflow: hidden;

  position: relative;

  ${(props) =>
    props.blur &&
    css`
      ::after {
        content: "";
        position: absolute;
        background: rgba(199, 221, 76, 0.1);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      div {
        filter: blur(2px);
      }
    `}
`;

export const MapContainerStyled = styled(MapContainer)`
  width: 100%;
  height: 100%;
`;

export const CircleStyled = styled(Circle)`
  fill: url(#heatmapCircleGradient);
  filter: url(#heatmapCircleFilter);
  stroke-width: 0;
  fill-opacity: 1;
`;
