import styled, { css } from "styled-components";
import { COLORS } from "../../../colors";
import { captionStyle } from "../../Typography";

export const Container = styled.div`
  margin-bottom: 20px;
`;

interface ModalProps {
  open: boolean;
}

export const ModalLink = styled.button<ModalProps>`
  background: transparent;
  border: none;
  color: ${COLORS.PRIMARY.MAIN};
  cursor: pointer;
  ${captionStyle};

  visibility: visible;
  display: block;

  ${(props) =>
    props.open &&
    css`
      display: none;
    `}

  :hover {
    text-decoration: underline;
  }
`;

export const ModalWrapper = styled.div<ModalProps>`
  background: #e8eaed;
  padding: 15px;
  border-radius: 20px;
  flex-direction: column;
  display: none;
  opacity: 0;
  will-change: opacity;
  transition: all linear 0.2s;

  ${(props) =>
    props.open &&
    css`
      display: flex;
      opacity: 1;
    `}

  p {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    margin-bottom: 20px;

    color: ${COLORS.SECONDARY.DARK};
  }

  a {
    color: inherit;

    :hover {
      text-decoration: none;
    }
  }

  b {
    font-weight: 700;
  }

  button {
    font-family: "Roboto", sans-serif;
    margin-left: auto;
  }
`;
