import { FC, Suspense, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { StylesProvider } from "@material-ui/core/styles";
import { routesComponents } from "./router";
import { Layout } from "./Layout";
import { ToastProvider } from "./ToastProvider";
import { MapProvider } from "./MapProvider";
import { GlobalStyle } from "./GlobalStyle";
import { Loader, wipeSubscriberData, isBrowserPaired } from "../../common";
import { useGATracking } from "./helper";
import { ProfileProvider } from "./ProfileProvider";

export const App: FC = () => {
  const element = useRoutes(routesComponents);
  useGATracking();

  useEffect(() => {
    if (!isBrowserPaired()) {
      wipeSubscriberData();
    }
  }, []);

  return (
    <HelmetProvider>
      <ProfileProvider>
        <MapProvider>
          <ToastProvider>
            <Helmet>
              <meta
                name="description"
                content="A call to consumers to voluntarily conserve energy when demand for power could outstrip supply."
              />
            </Helmet>
            <StylesProvider injectFirst>
              <GlobalStyle />
              <Layout>
                <Suspense fallback={<Loader />}>{element}</Suspense>
              </Layout>
            </StylesProvider>
          </ToastProvider>
        </MapProvider>
      </ProfileProvider>
    </HelmetProvider>
  );
};
