import styled, { css } from "styled-components";
import { COLORS } from "../../../common/colors";
import { Wrapper as FillerImage } from "../../../common/components/FillerImage/components";
import { Wrapper as Loader } from "../../../common/components/Loader/components";

interface WrapperProps {
  gradientBg?: boolean;
}

export const Wrapper = styled.section<WrapperProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${(props) =>
    props.gradientBg &&
    css`
      background: linear-gradient(
        90deg,
        rgba(113, 203, 149, 0.11) 5.35%,
        rgba(147, 171, 15, 0) 79.18%
      );
    `}
`;

export const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${FillerImage} {
    margin-top: 70px;

    @media (max-width: 800px) {
      margin-top: 40px;
    }
  }

  ${Loader} {
    flex: 1;
  }
`;

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
`;

export const LogoWrappers = styled.section`
  display: flex;
  justify-content: center;
`;

export const Footer = styled.footer`
  background: ${COLORS.BACKGROUND};
  margin-top: auto;
`;

export const FooterFeatured = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 0;

  @media only screen and (max-width: 640px) {
    margin-top: 30px;
  }

  @media (max-width: 780px) {
    flex-wrap: wrap;
    ${LogoWrappers} {
      margin: 0 auto;
    }
  }
`;

export const Menu = styled.section`
  display: flex;
  align-items: center;

  :first-child {
    margin-left: 0;
  }

  @media (max-width: 780px) {
    flex-basis: 100%;
    flex-direction: column;
    margin-top: 16px;
  }
`;

const styledLink = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;

  margin-left: 40px;
  color: ${COLORS.SECONDARY.MAIN};
  text-decoration: none;

  :hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 790px) {
    margin-left: 5px;
    margin-right: 10px;
  }

  @media (max-width: 590px) {
    margin: 8px 0;
  }
`;

export const StyledLink = styled.a`
  ${styledLink}
`;

export const CopyrightWrapper = styled.section`
  background: ${COLORS.SECONDARY.MAIN};

  div {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 780px) {
      flex-direction: column;
      align-items: flex-start;

      p:last-child {
        margin-top: 10px;
      }
    }
  }
`;

export const Copyright = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.8px;
  text-align: left;
  margin: 0 20px;
  color: ${COLORS.FONT.LIGHT};
  opacity: 0.8;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 16px 0;
`;

export const IconButton = styled.button`
  background: transparent;
  border: none;
  color: ${COLORS.SECONDARY.MAIN};
  margin-left: 48px;
  padding: 0;
  cursor: pointer;

  :first-of-type {
    margin-left: 35px;
  }

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 30px;
    height: 30px;
  }

  @media only screen and (max-width: 800px) {
    :first-of-type {
      margin-left: 5px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
