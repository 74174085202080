import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseFormReset } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ROUTES_PATHS, useProfile, useToast } from "../../../modules/App";
import { FlexApiService, LoggingService, useCaptcha } from "../../../common";
import { MessageType } from "./helpers";
import { ProfileUsernameType } from "../../../modules/App/ProfileProvider/context";
import { ToastType } from "../../../modules/App/ToastProvider";

export const useUpdateAdditionalContact = (
  setMessage: (msg: MessageType) => void,
  reset: UseFormReset<UpdateAdditionalContactFormData>,
  setNewUsername: (username: string) => void,
) => {
  const { showToast } = useToast();
  const { t } = useTranslation("settings");
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { executeRecaptcha } = useCaptcha();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const handleUpdateAdditionalContact = async (
    data: UpdateAdditionalContactFormData,
  ) => {
    if (!profile) {
      return;
    }

    if (!executeRecaptcha) {
      LoggingService.warning("reCAPTCHA is not available");
      return;
    }

    try {
      setIsUpdateLoading(true);
      const captchaToken = await executeRecaptcha();

      const response = await FlexApiService.post<
        never,
        UpdateAdditionalContactAPIPayload
      >("subscriber/profile/contact", {
        ...data,
        oldContact: profile.additionalContact,
        id: profile.uuid,
        captcha: captchaToken === "" ? undefined : captchaToken,
      });
      setIsUpdateLoading(false);

      if (response.status !== 201) {
        // navigate(ROUTES_PATHS.ERROR.INTERNAL);
        showToast(t("toast.error.not.allowed"), ToastType.ERROR);
        return;
      }

      if (profile?.additionalContact) {
        setMessage(MessageType.UPDATE_ADDITIONAL_CONTACT);
        setNewUsername(data.additionalContact);
      } else {
        setMessage(MessageType.ADD_ADDITIONAL_CONTACT);
      }

      if (profile?.mainContactType === ProfileUsernameType.EMAIL) {
        showToast(t("toast.sms.sent"), ToastType.SUCCESS);
      } else {
        showToast(t("toast.email.sent"), ToastType.SUCCESS);
      }

      reset();
    } catch (error) {
      LoggingService.error("Updating additional contact error: ", error);
      navigate(ROUTES_PATHS.ERROR.INTERNAL);
    }
  };

  return { handleUpdateAdditionalContact, isUpdateLoading };
};

type UpdateAdditionalContactFormData = {
  additionalContact: string;
};

type UpdateAdditionalContactAPIPayload = UpdateAdditionalContactFormData & {
  captcha?: string;
  id: string;
  oldContact?: string;
};
