import { createContext, useContext } from "react";
import { Position } from "../../../common";

export interface MapContext {
  pin?: Position;
  setPin: (pos: Position) => void;
  clearPin: () => void;
}

export const MapContext = createContext<MapContext>({
  setPin: () => {
    throw new Error("Used outside of context");
  },
  clearPin: () => {
    throw new Error("Used outside of context");
  },
});

export const useMapContext = () => useContext(MapContext);
