import { createGlobalStyle } from "styled-components";
import { COLORS } from "../../common";

export const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

  * {
    box-sizing: border-box;
  }

  .grecaptcha-badge { visibility: hidden; }

  .MuiAutocomplete-listbox {
    margin-right: 8px;
  }
  
  .custom__scroll::-webkit-scrollbar,
  .MuiAutocomplete-listbox::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .custom__scroll::-webkit-scrollbar-button,
  .MuiAutocomplete-listbox::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  .custom__scroll::-webkit-scrollbar-thumb,
  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
   background: ${COLORS.SURFACE};
   border-radius: 100px;
  }

  .custom__scroll::-webkit-scrollbar-thumb:hover,
  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.SECONDARY.LIGHT};
  }

  .custom__scroll::-webkit-scrollbar-thumb:active,
  .MuiAutocomplete-listbox::-webkit-scrollbar-thumb:active {
   background: ${COLORS.SECONDARY.LIGHT};
  }

  .custom__scroll::-webkit-scrollbar-track,
  .MuiAutocomplete-listbox::-webkit-scrollbar-track {
    background: ${COLORS.BACKGROUND};

    border-radius: 100px;
  }

  .custom__scroll::-webkit-scrollbar-track:hover
  .MuiAutocomplete-listbox::-webkit-scrollbar-track:hover {
    background: ${COLORS.SECONDARY.ULTRALIGHT};
  }

  .custom__scroll::-webkit-scrollbar-track:active
  .MuiAutocomplete-listbox::-webkit-scrollbar-track:active {
    background: ${COLORS.SECONDARY.ULTRALIGHT};
  }

  .custom__scroll::-webkit-scrollbar-corner
  .MuiAutocomplete-listbox::-webkit-scrollbar-corner {
    background: transparent;
  }

`;
